import React from "react";
import { Upload, Button, notification } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

const ExcelUploader = ({
  t,
  events,
  onDataUpload,
  loading,
  setLoading,
  fileListExcel,
  setFileListExcel,
  setExcelData,
}) => {
  const hasTitle = events?.formDetail?.title;
  const hasAddress = events?.formDetail?.address;
  const hasBirthDate = events?.formDetail?.birthDate;
  const hasBloodGroup = events?.formDetail?.bloodGroup;
  const hasFirstName = events?.formDetail?.firstName;
  const hasNote = events?.formDetail?.note;
  const hasIdPass = events?.formDetail?.idPass;
  const hasLastName = events?.formDetail?.lastName;
  const hasOtherContact = events?.formDetail?.otherContact;
  // const hasOtherPhone = events?.formDetail?.otherPhone;
  const hasPhone = events?.formDetail?.phone;

  const handleFileUpload = (file) => {
    setLoading(true);

    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // ดึงค่าจากคอลัมน์ M2 เท่านั้น (row[12] ของแถวที่ 2)
      const totalPrice = data[1] && data[1][16] ? data[1][16] : null;

      // กรองและ map ข้อมูลเฉพาะแถวที่มีข้อมูลครบทุกช่อง
      const formattedData = data
        .slice(1) // ข้ามหัวตาราง
        .filter(
          (row) =>
            row[0] &&
            row[1] &&
            row[2] &&
            row[3] &&
            row[4] &&
            row[5] &&
            row[6] &&
            row[7] &&
            row[8] &&
            // row[9] &&
            // row[10] &&
            // row[11] &&
            row[13] &&
            row[14] &&
            row[15]
        )
        .map((row) => {
          const rowData = {};

          const checkEmptyValue = (value) => (value ? value : ""); // ส่ง "" หากค่าว่าง

          if (hasTitle) rowData.title = row[0]; // คำนำหน้า
          if (hasFirstName) rowData.firstName = row[1]; // ชื่อ
          if (hasLastName) rowData.lastName = row[2]; // นามสกุล
          if (hasPhone) rowData.phone = row[3]; // เบอร์โทรศัพท์
          if (hasBirthDate) rowData.birthDate = row[4]; // วันเกิด
          if (hasAddress) rowData.address = row[5]; // ที่อยู่
          rowData.shirtSize = row[6]; // ขนาดเสื้อ (ไม่ต้องมีเงื่อนไข เนื่องจากจำเป็นต้องดึง)
          rowData.raceOption = row[7]; // ประเภท (ไม่ต้องมีเงื่อนไข เนื่องจากจำเป็นต้องดึง)
          rowData.ageGroup = row[8].replace(/\s*\((?:เฉพาะเพศชาย|ได้ทั้งชายและหญิง|เฉพาะเพศหญิง)\)/g, ""); // ลบข้อความเฉพาะเพศ

          // เงื่อนไขเพิ่มเติมสำหรับกรณีที่ข้อมูลเป็น "ไม่ต้องการข้อมูลส่วนนี้"
          if (hasIdPass) rowData.idPass = row[9] !== undefined ? row[9] : "";
          if (hasOtherContact && row[10] !== "ไม่ต้องการข้อมูลส่วนนี้") rowData.otherContact = row[10];
          if (hasBloodGroup && row[11] !== "ไม่ต้องการข้อมูลส่วนนี้") rowData.bloodGroup = row[11];
          if (hasNote) rowData.note = row[12] !== undefined ? row[12] : "";

          // แปลงค่า totalPrice ที่ได้จาก M2 (ไม่ต้องมีเงื่อนไข เนื่องจากจำเป็นต้องดึง)
          rowData.totalPrice = totalPrice;
          rowData.delivery = row[13] === "รับ"; // แปลงเป็น true/false
          rowData.lifeInsurance = row[14] === "รับ"; // แปลงเป็น true/false

          return rowData;
        });

      // Pass the formatted data back to the parent component
      onDataUpload(formattedData);
      setExcelData(formattedData);
      setLoading(false);
    };

    reader.readAsBinaryString(file);

    // อัปเดต state ของไฟล์ที่อัปโหลด
    setFileListExcel([file]); // ตั้งค่า fileListExcel ใหม่เป็นไฟล์ปัจจุบัน
    return false; // Prevent default upload behavior
  };

  const handleRemove = (file) => {
    setExcelData([]);
    setFileListExcel([]);
    notification.success({
      message: "ลบไฟล์สำเร็จ",
      description: `ไฟล์ ${file.name} ถูกลบแล้ว`,
    });
  };

  return (
    <Upload
      accept=".xlsx, .xls"
      beforeUpload={handleFileUpload}
      fileListExcel={fileListExcel} // ใช้ state สำหรับไฟล์ที่อัปโหลด
      maxCount={1}
      showUploadList={{
        showRemoveIcon: true, // แสดงไอคอนลบ
        removeIcon: <DeleteOutlined />, // ใช้ไอคอนลบจาก antd
      }}
      onRemove={handleRemove} // ฟังก์ชันที่เรียกเมื่อมีการลบไฟล์
    >
      <Button icon={<UploadOutlined />} loading={loading}>
        {loading ? t("sport-register-form-page.title-71") : t("sport-register-form-page.title-74")}
      </Button>
    </Upload>
  );
};

export default ExcelUploader;
