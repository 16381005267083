import { signInWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { Form, Input, Button, Row, Col, Typography, Space, Card, Divider, Image, message } from "antd";
import { FacebookFilled, GoogleOutlined } from "@ant-design/icons";
import { auth } from "./firebase";
import { toast } from "react-toastify";
import SignInWithGoogle from "./sign-in-with-google";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/app-provider";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const LoginForm = () => {
  const { language } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, values.username, values.password);

      window.location.reload();
    } catch (error) {
      console.log(error.message);
      message.error(t("login-page.title-5"), 2);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col>
        <Card
          size="small"
          style={{
            maxWidth: "400px",
            padding: "40px 20px",
            borderRadius: "60px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            margin: "20px 0px 20px 0px",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Row align="middle">
              <Col span={8}>
                <Image src="/paipao-logo.png" preview={false} width="80%" />
              </Col>
              <Col span={16}>
                <Title level={5} style={{ margin: 0 }}>
                  {t("login-page.title-1")}
                </Title>
                <Text>"{t("login-page.title-2")}"</Text>
              </Col>
            </Row>

            <Form name="login" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
              <Form.Item
                label={t("login-page.title-email")}
                name="username"
                rules={[{ required: true, message: t("login-page.title-3") }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label={t("login-page.title-password")}
                name="password"
                rules={[{ required: true, message: t("login-page.title-4") }]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={loading}
                  style={{ background: "#001978", borderColor: "#001978", margin: "20px 0px 20px 0px" }}
                >
                  {t("login-page.login-button")}
                </Button>
              </Form.Item>
              <Divider style={{ height: "2px", backgroundColor: "#d9d9d9", margin: "20px 0" }} />
              <Row gutter={[16, 16]}>
                {/* <Col span={24}>
                  <Button
                    icon={<FacebookFilled />}
                    size="large"
                    block
                    style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)" }}
                  >
                    Facebook
                  </Button>
                </Col> */}
                <Col span={24}>
                  <SignInWithGoogle navigate={navigate} t={t} />
                </Col>
                <Col span={24}>
                  <Button
                    size="large"
                    block
                    style={{ background: "#EFE26A", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)" }}
                    onClick={() => navigate(`/${language}/register`)}
                  >
                    <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                      {t("login-page.register-button")}
                    </Title>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginForm;
