import React from "react";
import {
  Form,
  Input,
  Card,
  Row,
  Col,
  DatePicker,
  Radio,
  Button,
  Checkbox,
  Typography,
  Divider,
  notification,
  Image,
  Upload,
  Select,
  Space,
  Flex,
  Modal,
} from "antd";
import { CameraOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { fetchEventById, postImageSlip, pushNewMember } from "../../services/fetch-events";
import dayjs from "dayjs";
import { Loading, OverlayLoading } from "../loading-spin";
import { useAppContext } from "../../context/app-provider";

const { Text, Title } = Typography;

const FormRegisterForFriend = ({
  activeButton,
  t,
  user,
  navigate,
  qrCodeData,
  eventId,
  screens,
  form,
  loading,
  loadingCard,
  setLoading,
  setLoadingCard,
  buttonInCardStyles,
  handleTabRegister,
}) => {
  const [events, setEvents] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const { language } = useAppContext();
  const [raceOptions, setRaceOptions] = React.useState([]);
  const [formDataList, setFormDataList] = React.useState([]);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const defaultRaceOption = raceOptions?.[0]?.key;

  React.useEffect(() => {
    if (events?.registerDetail) {
      setLoading(true);
      const options = Object.keys(events.registerDetail).map((key) => {
        const raceDetail = events.registerDetail[key];
        return {
          key: key,
          name: key,
          value: key,
          distance: raceDetail.distance,
          delivery: raceDetail.delivery,
          lifeInsurance: raceDetail.lifeInsurance,
          price: raceDetail.price,
          shirtSize: raceDetail.shirtSize,
          shirtSizeMale: raceDetail.shirtSizeMale,
          shirtSizeFemale: raceDetail.shirtSizeFemale,
          shirtImage: raceDetail.shirtImage,
          typeDetailMale: raceDetail.typeDetailMale,
          typeDetailFemale: raceDetail.typeDetailFemale,
        };
      });
      setRaceOptions(options);
      const defaultRaceOption = raceOptions?.[0]?.key; // เลือกตัวเลือกแรกเป็นค่าเริ่มต้น
      if (defaultRaceOption) {
        setFormDataList([{ raceOption: defaultRaceOption }]);
      }
      setLoading(false);
    } else {
      console.error("registerDetail is undefined or null");
    }
  }, [events]);

  React.useEffect(() => {
    const getEventData = async () => {
      setLoading(true);
      const { eventData } = await fetchEventById(eventId, language);
      setEvents(eventData);
      setLoading(false);
    };

    getEventData();
  }, [eventId, language]);

  const handleTabFormFriend = (index, raceOption, value, formData) => {
    const updatedFormDataList = [...formDataList];

    // อัปเดตค่าของ raceOption, delivery, lifeInsurance
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      title: "",
      raceOption: raceOption,
      delivery: false,
      lifeInsurance: false,
    };

    // การตรวจสอบเงื่อนไข 'title' และอัปเดต ageGroupOptions, shirtSizeOptions
    if (value === t("sport-register-form-page.title-32")) {
      updatedFormDataList[index].ageGroupOptions = formData?.typeDetailMale || [];
      updatedFormDataList[index].shirtSizeOptions = formData?.shirtSizeMale || [];
    } else if (value === t("sport-register-form-page.title-33") || value === t("sport-register-form-page.title-34")) {
      updatedFormDataList[index].ageGroupOptions = formData?.typeDetailFemale || [];
      updatedFormDataList[index].shirtSizeOptions = formData?.shirtSizeFemale || [];
    } else {
      updatedFormDataList[index].ageGroupOptions = [];
      updatedFormDataList[index].shirtSizeOptions = [];
    }

    // อัปเดตค่าใหม่ใน state
    setFormDataList(updatedFormDataList);
  };

  const handleCheckboxChange = (index, checked, field) => {
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [field]: checked,
    };
    setFormDataList(updatedFormDataList);
  };

  const handleAddForm = () => {
    setFormDataList([...formDataList, { raceOption: defaultRaceOption }]);
  };

  const handleDeleteForm = (index) => {
    setFormDataList(formDataList.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, key, value, formData) => {
    const formattedValue = value;
    const newFormData = [...formDataList];
    newFormData[index] = {
      ...newFormData[index],
      [key]: formattedValue,
    };

    if (key === "title") {
      if (value === t("sport-register-form-page.title-32")) {
        newFormData[index].ageGroupOptions = formData?.typeDetailMale || [];
        newFormData[index].shirtSizeOptions = formData?.shirtSizeMale || [];
      } else if (value === t("sport-register-form-page.title-33") || value === t("sport-register-form-page.title-34")) {
        newFormData[index].ageGroupOptions = formData?.typeDetailFemale || [];
        newFormData[index].shirtSizeOptions = formData?.shirtSizeFemale || [];
      } else {
        newFormData[index].ageGroupOptions = [];
        newFormData[index].shirtSizeOptions = [];
      }
    }
    setFormDataList(newFormData);
  };

  const calculatePriceBase = (formData) => {
    const selectedRaceOption = raceOptions?.find((option) => option?.key === formData.raceOption);

    const racePrice = selectedRaceOption?.price;
    return racePrice;
  };

  const calculateTotalPriceDelivery = (formData) => {
    const selectedRaceOption = raceOptions?.find((option) => option?.key === formData.raceOption);

    return formData?.delivery ? selectedRaceOption?.delivery : 0;
  };

  const calculateTotalPriceLifeInsurance = (formData) => {
    const selectedRaceOption = raceOptions?.find((option) => option?.key === formData.raceOption);

    return formData?.lifeInsurance ? selectedRaceOption?.lifeInsurance : 0;
  };

  const calculateTotalPrice = (formData) => {
    const priceBase = calculatePriceBase(formData);
    const priceDelivery = calculateTotalPriceDelivery(formData);
    const priceLifeInsurance = calculateTotalPriceLifeInsurance(formData);
    return priceBase + priceDelivery + priceLifeInsurance;
  };

  const totalPriceDelivery = formDataList.reduce((total, formData) => total + calculateTotalPriceDelivery(formData), 0);
  const totalPriceLifeInsurance = formDataList.reduce(
    (total, formData) => total + calculateTotalPriceLifeInsurance(formData),
    0
  );
  const totalPriceBase = formDataList.reduce((total, formData) => total + calculatePriceBase(formData), 0);
  const totalPrice = formDataList.reduce((total, formData) => total + calculateTotalPrice(formData), 0);

  const handleSubmit = async () => {
    try {
      setLoadingButton(true);
      if (formDataList.length === 0) {
        notification.error({
          message: t("sport-register-form-page.title-58"),
          description: t("sport-register-form-page.title-59"),
        });
        return;
      }

      const values = await form.validateFields();
      const requiredFields = [
        "title",
        "firstName",
        "lastName",
        "phone",
        "birthDate",
        "address",
        "shirtSize",
        "raceOption",
        // "idPass",
        // "otherContact",
        // "bloodGroup",
        // "otherPhone",
        "ageGroup",
      ];

      const fieldNames = {
        title: t("sport-register-form-page.title-1"),
        firstName: t("sport-register-form-page.title-2"),
        lastName: t("sport-register-form-page.title-3"),
        phone: t("sport-register-form-page.title-4"),
        birthDate: t("sport-register-form-page.title-10"),
        address: t("sport-register-form-page.title-12"),
        shirtSize: t("sport-register-form-page.title-60"),
        raceOption: t("sport-register-form-page.title-61"),
        // idPass: t("sport-register-form-page.title-5"),
        // otherContact: t("sport-register-form-page.title-62"),
        // bloodGroup: t("sport-register-form-page.title-7"),
        // otherPhone: t("sport-register-form-page.title-8"),
        ageGroup: t("sport-register-form-page.title-63"),
      };

      const missingFieldsDetails = formDataList
        .map((data, index) => {
          // กรอง requiredFields เพื่อข้ามฟิลด์ที่ไม่มีใน events?.formDetail
          // const relevantRequiredFields = requiredFields.filter((field) => events?.formDetail?.[field]);

          // const missing = relevantRequiredFields.filter((field) => !data[field] || data[field] === "");

          const missing = requiredFields.filter((field) => !data[field]);
          if (missing.length > 0) {
            return `${t("sport-register-form-page.title-64")} ${index + 1}: ${missing
              .map((field) => fieldNames[field])
              .join(", ")}`;
          }
          return null;
        })
        .filter(Boolean);

      if (missingFieldsDetails.length > 0) {
        const message = missingFieldsDetails.map((detail, index) => (
          <span key={index}>
            {detail}
            <br />
          </span>
        ));

        notification.error({
          message: t("sport-register-form-page.title-65"),
          description: message,
        });
        return;
      }

      const totalPrice = formDataList.reduce((total, formData) => total + calculateTotalPrice(formData), 0);

      const formData = formDataList.map((data, index) => ({
        ...values,
        ...data,
      }));

      if (fileList.length === 0) {
        notification.error({
          message: t("sport-register-form-page.title-35"),
          description: t("sport-register-form-page.title-36"),
        });
        return;
      }

      const currentDate = dayjs();
      const formattedDate = currentDate.format("DD/MM/YYYY");
      const formattedDateStorage = currentDate.format("DD-MM-YYYY");
      const formattedTime = currentDate.format("HH:mm:ss");

      let qrImage = "";

      if (fileList.length > 0) {
        await new Promise(async (resolve, reject) => {
          const fileName = `${eventId}/paymentSlip/${user?.email}_${formattedDateStorage}_${formattedTime}`;
          const reader = new FileReader();
          reader.readAsDataURL(fileList[0].originFileObj);
          reader.onload = async (e) => {
            const rawData = e.target.result;
            const fileURL = await postImageSlip(fileName, rawData);
            if (fileURL) {
              qrImage = fileURL;
              resolve(); // อัปโหลดสำเร็จ
            } else {
              console.error("Failed to upload the image slip.");
              reject(new Error("Failed to upload image"));
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file"));
          };
        });
      }

      const duplicatePhoneDetails = formDataList
        .map((data, index) => {
          const duplicateIndex = formDataList.findIndex((item, i) => item.phone === data.phone && i !== index);
          if (duplicateIndex !== -1) {
            return `${t("sport-register-form-page.title-64")} ${index + 1} ${t(
              "sport-register-form-page.phone-duplicate-with"
            )} ${t("sport-register-form-page.title-64")} ${duplicateIndex + 1}`;
          }
          return null;
        })
        .filter(Boolean);

      if (duplicatePhoneDetails.length > 0) {
        const duplicateMessage = duplicatePhoneDetails.map((detail, index) => (
          <span key={index}>
            {detail}
            <br />
          </span>
        ));

        Modal.warning({
          title: t("sport-register-form-page.title-81"),
          content: <div style={{ fontSize: "24px" }}>{duplicateMessage}</div>,
          width: 800,
          centered: true,
        });

        return;
      }

      const memberData = formData.map((fData) => ({
        date: formattedDate,
        time: formattedTime,
        uid: user?.uid,
        address: fData.address,
        ageGroup: fData.ageGroup,
        birthDate: fData.birthDate,
        firstName: fData.firstName,
        lastName: fData.lastName,
        phone: fData.phone,
        note: fData.note || "",
        idPass: fData.idPass || "",
        otherContact: fData.otherContact || "",
        bloodGroup: fData.bloodGroup || "",
        // otherPhone: fData.otherPhone || "",
        qrImage: qrImage,
        raceOption: fData.raceOption,
        delivery: fData.delivery || false,
        lifeInsurance: fData.lifeInsurance || false,
        shirtSize: fData.shirtSize,
        title: fData.title,
        totalPrice: totalPrice,
        status: "pending",
        refAcc: user?.email,
        groupName: fData.groupName || "",
      }));
      const response = await pushNewMember(`${activeButton}`, eventId, memberData);

      if (response === "Success") {
        setLoadingCard(true);
        setFileList([]);
        setTimeout(() => {
          setFormDataList([{ raceOption: defaultRaceOption }]);
        }, 0);
        navigate(`/${language}/sport-register/${activeButton}/member/${eventId}`);

        notification.open({
          message: null,
          description: (
            <div style={{ textAlign: "center" }}>
              <img
                src="/picture-success.jpg"
                alt="Success"
                style={{
                  width: "400px",
                  height: "auto",
                  marginBottom: "16px",
                  borderRadius: "8px",
                }}
              />
            </div>
          ),
          style: {
            width: "auto",
            background: "transparent",
            boxShadow: "none",
          },
          duration: 3,
          placement: "top",
        });
      } else if (response !== "Success") {
        Modal.error({
          title: t("sport-register-form-page.title-77"),
          content: (
            <div style={{ fontSize: "24px" }}>
              {t("sport-register-form-page.title-78")} <strong>{response.join(", ")}</strong>
              <br />
              {t("sport-register-form-page.title-79")}
              {t("sport-register-form-page.title-80")}
            </div>
          ),
          width: 800,
          centered: true,
          onOk() {},
        });
      }
    } catch (error) {
      notification.error({
        message: t("sport-register-form-page.title-38"),
        description: t("sport-register-form-page.title-39"),
      });
    } finally {
      setLoadingCard(false);
      setLoadingButton(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (loadingCard) {
    return <OverlayLoading />;
  }

  const titleOptions = [
    { value: t("sport-register-form-page.title-32"), label: t("sport-register-form-page.title-32") },
    { value: t("sport-register-form-page.title-33"), label: t("sport-register-form-page.title-33") },
    { value: t("sport-register-form-page.title-34"), label: t("sport-register-form-page.title-34") },
  ];

  const bloodGroupOptions = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "AB", label: "AB" },
    { value: "O", label: "O" },
  ];

  const handleClearImage = () => {
    setFileList([]);
  };

  const beforeUpload = (file) => {
    // Example: Limit file size to 3MB
    const isValidSize = file.size / 1024 / 1024 < 3;
    if (!isValidSize) {
      notification.error({
        message: t("sport-register-form-page.title-40"),
        description: t("sport-register-form-page.title-41"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    // Example: Allow only jpg and png files
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("sport-register-form-page.title-42"),
        description: t("sport-register-form-page.title-43"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    return isValidSize && isJpgOrPng; // Return false to prevent upload
  };

  return (
    <>
      <Card
        loading={loading}
        style={{
          backgroundColor: "rgba(255, 145, 144, 0.2)",
          borderRadius: 0,
          borderTop: "15px solid ",
          borderColor: "#130C63",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
      >
        {screens.xs ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="tab-select-form-friend"
          >
            <Select defaultValue="2" onChange={handleTabRegister} style={{ width: "50%" }}>
              <Select.Option value="1">{t("sport-register-form-page.header-sub-card-button-1")}</Select.Option>
              <Select.Option value="2">{t("sport-register-form-page.header-sub-card-button-2")}</Select.Option>
              <Select.Option value="3">{t("sport-register-form-page.header-sub-card-button-3")}</Select.Option>
              {events?.onlyShirt !== undefined ? (
                <Select.Option value="4">{t("sport-register-form-page.header-sub-card-button-4")}</Select.Option>
              ) : null}
            </Select>
          </div>
        ) : (
          <Row gutter={[16, 32]}>
            <Col xs={4} sm={5} md={4} lg={4} xl={3} xxl={3} offset={3}>
              <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("1")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-1")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[1]} onClick={() => handleTabRegister("2")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-2")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[2]} onClick={() => handleTabRegister("3")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-3")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={3} xxl={3}>
              {events?.onlyShirt !== undefined ? (
                <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("4")}>
                  <Space direction="vertical" align="center">
                    <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                      {t("sport-register-form-page.header-sub-card-button-4")}
                    </Text>
                  </Space>
                </Button>
              ) : null}
            </Col>
          </Row>
        )}

        {formDataList.map((formData, index) => {
          const selectedRaceOption = raceOptions.find((option) => option.key === formData.raceOption);
          const racePrice = selectedRaceOption?.price;
          const raceDistance = selectedRaceOption?.distance;
          const raceType = events?.[formData?.raceOption];

          return (
            <Form key={index} layout="vertical">
              <Card
                loading={loading}
                style={{
                  maxWidth: "1280px",
                  width: "100%",
                  margin: "0 auto",
                  borderTop: "15px solid",
                  borderColor: "#FF9190",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "none",
                  borderRadius: "40px",
                  padding: "20px",
                  position: "relative",
                }}
              >
                {index > 0 ? (
                  <Button
                    type="primary"
                    icon={<CloseOutlined />}
                    onClick={() => handleDeleteForm(index)}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "transparent",
                      color: "#FF9190",
                      fontSize: "18px",
                    }}
                  />
                ) : null}
                <Row justify="center" align="middle" gutter={[16, 16]}>
                  {events?.formDetail?.title ? (
                    <Col xs={24} sm={12} md={8} lg={5} xl={4} xxl={4}>
                      <Form.Item
                        label={t("sport-register-form-page.title-1")}
                        name="title"
                        layout="horizontal"
                        rules={[{ required: true, message: t("sport-register-form-page.title-44") }]}
                      >
                        <Select
                          className="title-select"
                          value={formData.title || ""}
                          onChange={(value) => handleInputChange(index, "title", value, selectedRaceOption)}
                        >
                          {titleOptions.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {formData.title === "" && (
                        <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                          {t(
                            "หากข้อมูลไม่เปลี่ยนแปลง กรุณาลองเลือกคำนำหน้าอื่นและกลับมาเลือกคำนำหน้าที่ต้องการอีกครั้ง"
                          )}
                        </div>
                      )}
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.firstName ? (
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                      <Form.Item
                        label={t("sport-register-form-page.title-2")}
                        name="firstName"
                        rules={[{ required: true, message: t("sport-register-form-page.title-45") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.firstName || ""}
                          onChange={(e) => handleInputChange(index, "firstName", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.lastName ? (
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                      <Form.Item
                        label={t("sport-register-form-page.title-3")}
                        name="lastName"
                        rules={[{ required: true, message: t("sport-register-form-page.title-46") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.lastName || ""}
                          onChange={(e) => handleInputChange(index, "lastName", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.phone ? (
                    <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <Form.Item
                        label={t("sport-register-form-page.title-4")}
                        name="phone"
                        rules={[{ required: true, message: t("sport-register-form-page.title-47") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.phone || ""}
                          onChange={(e) => handleInputChange(index, "phone", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.birthDate ? (
                    <Col xs={12} sm={15} md={12} lg={8} xl={6} xxl={6}>
                      <Form.Item
                        label={t("sport-register-form-page.title-10")}
                        name="birthDate"
                        rules={[{ required: true, message: t("sport-register-form-page.title-52") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.birthDate || ""}
                          placeholder={t("sport-register-form-page.title-75")}
                          onChange={(e) => handleInputChange(index, "birthDate", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.idPass ? (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        label={t("sport-register-form-page.title-5")}
                        name="idPass"
                        // rules={[{ required: true, message: t("sport-register-form-page.title-48") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.idPass || ""}
                          onChange={(e) => handleInputChange(index, "idPass", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.otherContact ? (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        label={t("sport-register-form-page.title-6")}
                        name="otherContact"
                        // rules={[{ required: true, message: t("sport-register-form-page.title-49") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.otherContact || ""}
                          onChange={(e) => handleInputChange(index, "otherContact", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.bloodGroup ? (
                    <Col xs={12} sm={10} md={8} lg={6} xl={4} xxl={4}>
                      <Form.Item
                        label={t("sport-register-form-page.title-7")}
                        name="bloodGroup"
                        layout="horizontal"
                        // rules={[{ required: true, message: t("sport-register-form-page.title-50") }]}
                      >
                        <Select
                          className="title-select"
                          value={formData.bloodGroup || ""}
                          onChange={(value) => handleInputChange(index, "bloodGroup", value, selectedRaceOption)}
                        >
                          {bloodGroupOptions.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {/* {events?.formDetail?.otherPhone ? (
                    <Col xs={12} sm={15} md={12} lg={10} xl={6} xxl={6}>
                      <Form.Item
                        label={t("sport-register-form-page.title-8")}
                        name="otherPhone"
                        rules={[{ required: true, message: t("sport-register-form-page.title-51") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.otherPhone || ""}
                          onChange={(e) => handleInputChange(index, "otherPhone", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )} */}
                  {events?.formDetail?.groupName ? (
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <Form.Item label={t("sport-register-form-page.title-11")} name="groupName" layout="horizontal">
                        <Input
                          value={formData.groupName || ""}
                          onChange={(e) => handleInputChange(index, "groupName", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.address ? (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        label={t("sport-register-form-page.title-12")}
                        name="address"
                        rules={[{ required: true, message: t("sport-register-form-page.title-53") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.address || ""}
                          onChange={(e) => handleInputChange(index, "address", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {events?.formDetail?.note ? (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        label={t("sport-register-form-page.title-76")}
                        name="note"
                        // rules={[{ required: true, message: t("sport-register-form-page.title-48") }]}
                        layout="horizontal"
                      >
                        <Input
                          value={formData.note || ""}
                          onChange={(e) => handleInputChange(index, "note", e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card>
              {screens.xs ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "10px 0px 0px 0px",
                  }}
                  className="tab-select-race"
                >
                  <Select
                    defaultValue={formData.raceOption}
                    onChange={(value) => handleTabFormFriend(index, value)}
                    style={{ width: "50%" }}
                  >
                    {raceOptions.length > 0 &&
                      raceOptions.map((option) => (
                        <Select.Option key={option.key} value={option.key}>
                          {option.name.toUpperCase()}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              ) : (
                <Row gutter={[16, 32]} style={{ margin: "10px 0px 0px 0px", rowGap: 0 }}>
                  {raceOptions.map((option) => (
                    <Col
                      key={option.key}
                      xs={0}
                      sm={{ span: 4, offset: 1 }}
                      md={{ span: 4, offset: 2 }}
                      lg={4}
                      xl={3}
                      xxl={{ span: 3, offset: 2 }}
                    >
                      <Button
                        block
                        size="large"
                        type={selectedRaceOption?.key === option.key ? "primary" : "default"}
                        onClick={() => handleTabFormFriend(index, option.value)}
                        style={{
                          borderRadius: "10px 10px 0px 0px",
                          backgroundColor: selectedRaceOption?.key === option.key ? "#ffffff" : "#d9d9d9",
                          color: selectedRaceOption?.key === option.key ? "#000000" : "#000000",
                        }}
                      >
                        <Text strong style={{ margin: 0 }}>
                          {option.name.toUpperCase()}
                        </Text>
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
              <Card
                style={{
                  maxWidth: "1280px",
                  width: "100%",
                  margin: "0 auto",
                  borderRadius: "40px",
                }}
              >
                <Row>
                  <Col offset={1}>{`${t("sport-register-form-page.title-13")} ${raceDistance} Km. ${t(
                    "sport-register-form-page.title-14"
                  )}: ${selectedRaceOption?.name} ${t("sport-register-form-page.title-15")} ${racePrice} ${t(
                    "sport-register-form-page.title-16"
                  )}`}</Col>
                </Row>
                <Row>
                  <Col xs={24} sm={8} md={12} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name="ageGroup"
                      rules={[{ required: true, message: t("sport-register-form-page.title-54") }]}
                    >
                      <Radio.Group
                        value={formData.ageGroup || ""}
                        onChange={(e) => handleInputChange(index, "ageGroup", e.target.value)}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Space size="middle" direction="vertical">
                          {formDataList[index]?.ageGroupOptions && formDataList[index]?.ageGroupOptions.length > 0 ? (
                            formDataList[index].ageGroupOptions.map((value, idx) => (
                              <Form.Item key={idx} style={{ marginBottom: "10px" }}>
                                <Radio value={value} style={{ marginTop: idx === 0 ? "10px" : "0px" }}>
                                  {value}
                                </Radio>
                              </Form.Item>
                            ))
                          ) : (
                            <Title level={5}>{t("sport-register-form-page.title-17")}</Title>
                          )}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={16} md={12} lg={8} xl={8} xxl={8}>
                    {selectedRaceOption?.shirtImage && <Image src={selectedRaceOption.shirtImage} alt="Shirt Image" />}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Form.Item name="shirtSize" rules={[{ required: true, message: "กรุณาเลือกขนาดเสื้อ" }]}>
                      <Radio.Group
                        value={formData.shirtSize || ""}
                        onChange={(e) => handleInputChange(index, "shirtSize", e.target.value)}
                      >
                        {formDataList[index]?.shirtSizeOptions && formDataList[index]?.ageGroupOptions.length > 0 ? (
                          <>
                            <Title level={4}>{t("sport-register-form-page.header-sizeShirt-1")}</Title>
                            <Row gutter={[16, 16]} wrap>
                              {Object.entries(formDataList[index].shirtSizeOptions)
                                .sort(([, aChest], [, bChest]) => {
                                  const chestA = parseInt(aChest.split(" ")[1], 10);
                                  const chestB = parseInt(bChest.split(" ")[1], 10);
                                  return chestA - chestB;
                                })
                                .map(([size, chest]) => (
                                  <Col xs={24} sm={16} md={12} lg={12} xl={12} xxl={12} key={size}>
                                    <Radio value={size}>
                                      {size} {chest}
                                    </Radio>
                                  </Col>
                                ))}
                            </Row>
                          </>
                        ) : (
                          <Title level={5}>{t("sport-register-form-page.title-18")}</Title>
                        )}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Row gutter={[16, 32]} style={{ margin: "20px 0px 0px 0px", rowGap: 0 }}>
                <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18} offset={3}>
                  <Text style={{ margin: 0, fontSize: "18px" }}>{t("sport-register-form-page.title-19")}</Text>
                </Col>
              </Row>
              <Card
                style={{
                  maxWidth: "1280px",
                  width: "100%",
                  margin: "0 auto",
                  borderRadius: "40px",
                  marginTop: "10px",
                }}
              >
                {selectedRaceOption?.delivery != null || selectedRaceOption?.lifeInsurance != null ? (
                  <>
                    <Form.Item name="delivery" valuePropName="checked">
                      <Checkbox
                        style={{ display: "flex" }}
                        disabled={selectedRaceOption?.delivery == null}
                        checked={formDataList[index]?.delivery || false}
                        onChange={(e) => handleCheckboxChange(index, e.target.checked, "delivery")}
                      >
                        {selectedRaceOption?.delivery != null
                          ? `${t("sport-register-form-page.title-21")} ${selectedRaceOption.delivery} ${t(
                              "sport-register-form-page.title-16"
                            )}`
                          : t("sport-register-form-page.title-56")}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="lifeInsurance" valuePropName="checked">
                      <Checkbox
                        style={{ display: "flex" }}
                        disabled={selectedRaceOption?.lifeInsurance == null}
                        checked={formDataList[index]?.lifeInsurance || false}
                        onChange={(e) => handleCheckboxChange(index, e.target.checked, "lifeInsurance")}
                      >
                        {selectedRaceOption?.lifeInsurance != null
                          ? `${t("sport-register-form-page.title-22")} ${selectedRaceOption.lifeInsurance}${t(
                              "sport-register-form-page.title-16"
                            )}`
                          : t("sport-register-form-page.title-57")}
                      </Checkbox>
                    </Form.Item>
                  </>
                ) : (
                  <Text>{t("sport-register-form-page.title-20")}</Text>
                )}
              </Card>
              <Divider style={{ height: "2px", backgroundColor: "#842D2D" }} />
            </Form>
          );
        })}
        <Button
          type="text"
          icon={<PlusOutlined />}
          size="large"
          onClick={handleAddForm}
          style={{
            color: "#FFFFFF", // สีข้อความเป็นสีขาว เพื่อความโดดเด่น
            border: "2px solid #D32F2F", // เส้นขอบสีแดงเข้ม
            borderRadius: "8px",
            padding: "8px 16px",
            backgroundColor: "#F44336", // พื้นหลังสีแดงสด
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        >
          {t("sport-register-form-page.button-add-form")}
        </Button>

        <Card
          style={{
            maxWidth: "1280px",
            width: "100%",
            margin: "0 auto",
            borderRadius: "40px",
            padding: "20px 40px 20px 40px",
            marginTop: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <Row justify="center" align="middle" gutter={[32, 32]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
              <Image src={qrCodeData?.qrPayment1} />
            </Col>

            {/* <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
              <Image src={qrCodeData?.qrPayment2} />
            </Col> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={{ span: 6, offset: 2 }}>
              <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                {t("sport-register-form-page.title-24")}
              </Title>
              <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                {t("sport-register-form-page.title-25")}
              </Title>
              <Form.Item name="qrImage" layout="horizontal">
                {fileList.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    <Image src={URL.createObjectURL(fileList[0].originFileObj)} alt="Image preview" />
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={handleClearImage}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        border: "none",
                      }}
                    />
                  </div>
                ) : (
                  <Upload.Dragger
                    listType="picture"
                    name="files"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                    showUploadList={false}
                    customRequest={() => {
                      /* no action required */
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <CameraOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  </Upload.Dragger>
                )}
              </Form.Item>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text>{t("sport-register-form-page.title-27")}</Text>
                </Col>
                <Col>
                  <Text>
                    {totalPriceBase} {t("sport-register-form-page.title-16")}
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text>{t("sport-register-form-page.title-28")}</Text>
                </Col>
                <Col>
                  <Text>
                    {totalPriceDelivery} {t("sport-register-form-page.title-16")}
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text>{t("sport-register-form-page.title-29")}</Text>
                </Col>
                <Col>
                  <Text>
                    {totalPriceLifeInsurance} {t("sport-register-form-page.title-16")}
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={5} style={{ margin: 0, color: "#038416" }}>
                    {t("sport-register-form-page.title-30")}
                  </Title>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0, color: "#038416" }}>
                    {totalPrice} {t("sport-register-form-page.title-16")}
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Card>
      <Flex justify="center" style={{ padding: "20px 0px 10px 0px" }}>
        <Button
          type="text"
          size="large"
          onClick={handleSubmit}
          loading={loadingButton}
          style={{ backgroundColor: "#038416", color: "#FFFFFF" }}
        >
          {t("sport-register-form-page.button-send")}
        </Button>
      </Flex>
    </>
  );
};

export default FormRegisterForFriend;
