import React from "react";
import {
  Form,
  Input,
  Card,
  Row,
  Col,
  DatePicker,
  Radio,
  Button,
  Checkbox,
  Typography,
  notification,
  Image,
  Upload,
  Select,
  Space,
  Flex,
  InputNumber,
  Divider,
} from "antd";
import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import { fetchEventById, postImageSlip, pushNewOrderShirt } from "../../services/fetch-events";
import Loading from "../loading-spin";
import dayjs from "dayjs";
import { useAppContext } from "../../context/app-provider";

const { Text, Title } = Typography;

const FormOrderShirt = ({
  t,
  activeButton,
  user,
  qrCodeData,
  eventId,
  screens,
  form,
  loading,
  loadingCard,
  setLoading,
  setLoadingCard,
  buttonInCardStyles,
  handleTabRegister,
}) => {
  const [events, setEvents] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const [shirtDetails, setShirtDetails] = React.useState([]);
  const [selectedSizes, setSelectedSizes] = React.useState({});
  const [quantities, setQuantities] = React.useState({});
  const [totalShirtPrice, setTotalShirtPrice] = React.useState(0);
  const [deliveryPrice, setDeliveryPrice] = React.useState(0);
  const [deliveryChecked, setDeliveryChecked] = React.useState(0);
  const { language } = useAppContext();

  React.useEffect(() => {
    if (events?.onlyShirt) {
      const options = Object.keys(events.onlyShirt).map((key) => {
        const shirtDetails = events.onlyShirt[key];
        return {
          key: key,
          name: key,
          value: key,
          priceDelivery: shirtDetails.priceDelivery,
          price: shirtDetails.price,
          shirtImage: shirtDetails.shirtImage,
          shirtName: shirtDetails.shirtName,
          shirtSize: shirtDetails.shirtSize,
        };
      });
      setShirtDetails(options);
    } else {
      console.error("registerDetail is undefined or null");
    }
  }, [events]); // แค่ตรวจสอบว่าเมื่อใดที่ events เปลี่ยนแปลง

  const calculateDeliveryPrice = (quantities) => {
    const shirtCountData = events?.onlyShirt;
    Object.keys(shirtCountData).forEach((key) => {
      shirtCountData[key] = 0;
    });
    const totalShirts = shirtCountData;

    let priceDelivery = 0;

    Object.keys(quantities).forEach((shirtName) => {
      const selectedShirt = shirtDetails.find((shirt) => shirt.name === shirtName);
      if (selectedShirt) {
        shirtCountData[shirtName] = 0;
        totalShirts[shirtName] = 0;
        // const allTotalShirts = 0;
        Object.values(quantities[shirtName]).forEach((quantity) => {
          totalShirts[shirtName] += quantity || 0; // Count total shirts
        });
        if (deliveryChecked) {
          if (totalShirts[shirtName] > 3) {
            shirtCountData[shirtName] =
              Math.round((((totalShirts[shirtName] - 3) * 0.2 + 1) * selectedShirt.priceDelivery) / 10) * 10;
          } else if (totalShirts[shirtName] <= 3 && totalShirts[shirtName] > 0) {
            shirtCountData[shirtName] = selectedShirt.priceDelivery;
          }
          priceDelivery = Object.values(shirtCountData).reduce((acc, value) => acc + value, 0);
        } else {
          priceDelivery = 0;
        }
      }
    });
    return priceDelivery;
  };

  const handleCheckboxChange = (checkedValues, shirtIndex) => {
    setSelectedSizes((prevState) => ({
      ...prevState,
      [shirtIndex]: checkedValues,
    }));

    const selectedShirt = shirtDetails[shirtIndex];
    const newShirtTotal = { ...quantities };
    let newTotalPrice = 0;

    selectedShirt.shirtSize.forEach((size) => {
      const sizeKey = size.split(" ")[0];
      const quantityKey = `shirtQuantity_${selectedShirt.name}_${sizeKey}`;

      if (!checkedValues.includes(sizeKey)) {
        // ถ้า size นี้ไม่ได้ถูกเลือก ให้ล้างจำนวนออกจากฟอร์ม
        form.setFieldsValue({ [quantityKey]: null });
        // ลบจำนวนของ size นี้ออกจาก newShirtTotal
        if (newShirtTotal[selectedShirt.name]) {
          delete newShirtTotal[selectedShirt.name][sizeKey];
        }
      }
    });

    // คำนวณราคาใหม่สำหรับเสื้อที่เลือก
    Object.keys(newShirtTotal).forEach((shirtName) => {
      const shirt = shirtDetails.find((s) => s.name === shirtName);
      if (shirt) {
        Object.keys(newShirtTotal[shirtName]).forEach((size) => {
          newTotalPrice += newShirtTotal[shirtName][size] * shirt.price;
        });
      }
    });

    setTotalShirtPrice(newTotalPrice);
    setQuantities(newShirtTotal);

    // คำนวณ priceDelivery
    const priceDelivery = calculateDeliveryPrice(newShirtTotal);
    setDeliveryPrice(priceDelivery);
  };

  const handleQuantityChange = (value, shirtIndex, sizeKey) => {
    const selectedShirt = shirtDetails[shirtIndex]; // ค้นหาเสื้อจาก index
    const newShirtTotal = { ...quantities }; // ดึงข้อมูล shirtTotal ที่เก็บข้อมูลไว้แล้ว

    // อัปเดตจำนวนใน newShirtTotal
    if (!newShirtTotal[selectedShirt.name]) {
      newShirtTotal[selectedShirt.name] = {}; // ถ้ายังไม่มีข้อมูลเสื้อนี้ใน quantities
    }

    newShirtTotal[selectedShirt.name][sizeKey] = value; // อัปเดตจำนวนของ size ที่ถูกเลือก

    // อัปเดต quantities
    setQuantities(newShirtTotal);

    // คำนวณราคาใหม่
    let newTotalPrice = 0;
    shirtDetails.forEach((shirt) => {
      if (newShirtTotal[shirt.name]) {
        Object.keys(newShirtTotal[shirt.name]).forEach((size) => {
          newTotalPrice += newShirtTotal[shirt.name][size] * shirt.price;
        });
      }
    });

    setTotalShirtPrice(newTotalPrice); // อัปเดตราคาทั้งหมด

    // ตั้งค่า deliveryChecked เป็น true
    setDeliveryChecked(true); // อัปเดตสถานะจัดส่ง
    // ตรวจสอบว่าติ๊ก "ให้จัดส่งเสื้อ" หรือไม่ ถ้าใช่ให้คำนวณค่าส่ง
    const priceDelivery = calculateDeliveryPrice(newShirtTotal);
    setDeliveryPrice(priceDelivery);
  };

  const handleDeliveryCheckboxChange = (e) => {
    const { checked } = e.target;
    setDeliveryChecked(checked); // อัปเดตสถานะ deliveryChecked
  };

  const realDeliveryPrice = deliveryChecked ? deliveryPrice : 0;
  const totalPrice = totalShirtPrice + realDeliveryPrice;

  React.useEffect(() => {
    const getEventData = async () => {
      const { eventData } = await fetchEventById(eventId, language);
      setEvents(eventData);
      setLoading(false);
    };

    getEventData();
  }, [eventId, language]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();
      const formData = { ...values, totalPrice };
      const currentDate = dayjs();
      const formattedDate = currentDate.format("DD/MM/YYYY");
      const formattedDateStorage = currentDate.format("DD-MM-YYYY");
      const formattedTime = currentDate.format("HH:mm:ss");

      formData.date = formattedDate;
      formData.time = formattedTime;

      if (fileList.length === 0) {
        notification.error({
          message: t("sport-register-form-page.title-35"),
          description: t("sport-register-form-page.title-36"),
        });
        return;
      }

      if (fileList.length > 0) {
        await new Promise(async (resolve, reject) => {
          const fileName = `${eventId}/paymentSlip/${user?.email}_${formattedDateStorage}_${formattedTime}`;
          const reader = new FileReader();
          reader.readAsDataURL(fileList[0].originFileObj);
          reader.onload = async (e) => {
            const rawData = e.target.result;
            const fileURL = await postImageSlip(fileName, rawData);
            if (fileURL) {
              formData.qrImage = fileURL;
              console.log("fileURL:  " + formData.qrImage);
              resolve(); // อัปโหลดสำเร็จ
            } else {
              console.error("Failed to upload the image slip.");
              reject(new Error("Failed to upload image")); // อัปโหลดไม่สำเร็จ
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file")); // ข้อผิดพลาดในการอ่านไฟล์
          };
        });
      }

      const shirtTotal = {};

      // Map ข้อมูลเสื้อและจำนวน
      shirtDetails.forEach((shirt) => {
        const shirtName = shirt.name;
        shirtTotal[shirtName] = {};

        shirt.shirtSize.forEach((size) => {
          const sizeKey = size.split(" ")[0]; // ดึงขนาดของเสื้อ เช่น XS, S, M
          const quantityKey = `shirtQuantity_${shirtName}_${sizeKey}`; // สร้าง key สำหรับ quantity
          const quantityValue = formData[quantityKey]; // ดึงค่าจำนวนจาก formData
          // เพิ่มเฉพาะจำนวนที่มากกว่า 0
          shirtTotal[shirtName][sizeKey] = quantityValue || 0;
        });
      });
      const orderData = {
        date: formattedDate,
        time: formattedTime,
        uid: user?.uid,
        address: formData.address || "",
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        qrImage: formData.qrImage,
        delivery: formData.delivery || false,
        shirtTotal, // เก็บข้อมูลขนาดเสื้อและจำนวนใน shirtTotal
        title: formData.title,
        totalPrice: formData.totalPrice,
        status: "pending",
        refAcc: user?.email,
      };
      // เรียกใช้ API สำหรับเพิ่มสมาชิกใหม่
      await pushNewOrderShirt(`${activeButton}`, eventId, orderData);

      form.resetFields();
      setTotalShirtPrice(0);
      setDeliveryPrice(0);
      setSelectedSizes({});
      setFileList([]);
      notification.success({
        message: t("sport-register-form-page.title-37"),
      });
    } catch (error) {
      // แสดงข้อผิดพลาดถ้ามี
      notification.error({
        message: t("sport-register-form-page.title-38"),
        description: t("sport-register-form-page.title-39"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClearImage = () => {
    setFileList([]);
  };

  const beforeUpload = (file) => {
    // Example: Limit file size to 2MB
    const isValidSize = file.size / 1024 / 1024 < 3;
    if (!isValidSize) {
      notification.error({
        message: t("sport-register-form-page.title-40"),
        description: t("sport-register-form-page.title-41"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    // Example: Allow only jpg and png files
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("sport-register-form-page.title-42"),
        description: t("sport-register-form-page.title-43"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    return isValidSize && isJpgOrPng; // Return false to prevent upload
  };

  return (
    <>
      <Card
        loading={loadingCard}
        style={{
          backgroundColor: "rgba(94, 114, 235, 0.2)",
          borderRadius: 0,
          borderTop: "15px solid ",
          borderColor: "#130C63",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
      >
        {screens.xs ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="tab-select-form-self"
          >
            <Select defaultValue="4" onChange={handleTabRegister} style={{ width: "50%" }}>
              <Select.Option value="1">{t("sport-register-form-page.header-sub-card-button-1")}</Select.Option>
              <Select.Option value="2">{t("sport-register-form-page.header-sub-card-button-2")}</Select.Option>
              <Select.Option value="3">{t("sport-register-form-page.header-sub-card-button-3")}</Select.Option>
              {events?.onlyShirt !== undefined ? (
                <Select.Option value="4">{t("sport-register-form-page.header-sub-card-button-4")}</Select.Option>
              ) : null}
            </Select>
          </div>
        ) : (
          <Row gutter={[16, 32]}>
            <Col xs={4} sm={5} md={4} lg={4} xl={3} xxl={3} offset={3}>
              <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("1")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-1")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[1]} onClick={() => handleTabRegister("2")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-2")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[2]} onClick={() => handleTabRegister("3")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-3")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={3} xxl={3}>
              {events?.onlyShirt !== undefined ? (
                <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("4")}>
                  <Space direction="vertical" align="center">
                    <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                      {t("sport-register-form-page.header-sub-card-button-4")}
                    </Text>
                  </Space>
                </Button>
              ) : null}
            </Col>
          </Row>
        )}

        <Form form={form} layout="vertical">
          <Card
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "0 auto",
              borderTop: "15px solid",
              borderColor: "#130C63",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
              borderRadius: "40px",
              padding: "20px",
            }}
          >
            <Row justify="center" align="middle" gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={5} xl={4} xxl={4}>
                <Form.Item
                  label={t("sport-register-form-page.title-1")}
                  name="title"
                  layout="horizontal"
                  rules={[{ required: true, message: t("sport-register-form-page.title-44") }]}
                >
                  <Select className="title-select">
                    <Select.Option value={t("sport-register-form-page.title-32")}>
                      {t("sport-register-form-page.title-32")}
                    </Select.Option>
                    <Select.Option value={t("sport-register-form-page.title-33")}>
                      {t("sport-register-form-page.title-33")}
                    </Select.Option>
                    <Select.Option value={t("sport-register-form-page.title-34")}>
                      {t("sport-register-form-page.title-34")}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <Form.Item
                  label={t("sport-register-form-page.title-2")}
                  name="firstName"
                  rules={[{ required: true, message: t("sport-register-form-page.title-45") }]}
                  layout="horizontal"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                <Form.Item
                  label={t("sport-register-form-page.title-3")}
                  name="lastName"
                  rules={[{ required: true, message: t("sport-register-form-page.title-46") }]}
                  layout="horizontal"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item
                  label={t("sport-register-form-page.title-4")}
                  name="phone"
                  rules={[{ required: true, message: t("sport-register-form-page.title-47") }]}
                  layout="horizontal"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={{ span: 18, offset: 4 }}
                xl={{ span: 18, offset: 4 }}
                xxl={{ span: 18, offset: 4 }}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.delivery !== currentValues.delivery}
                >
                  {({ getFieldValue }) => (
                    <Form.Item
                      label={t("sport-register-form-page.title-12")}
                      name="address"
                      rules={[{ required: getFieldValue("delivery"), message: t("sport-register-form-page.title-53") }]}
                      layout="horizontal"
                    >
                      <Input disabled={!getFieldValue("delivery")} />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="delivery" valuePropName="checked">
              <Checkbox onChange={handleDeliveryCheckboxChange} style={{ display: "flex" }}>
                {t("sport-register-form-page.title-31")}
              </Checkbox>
            </Form.Item>
          </Card>
          {shirtDetails.map((shirt, index) => (
            <Card
              style={{
                maxWidth: "1280px",
                width: "100%",
                margin: "0 auto",
                marginTop: "20px",
                borderRadius: "40px",
              }}
            >
              <Row align="middle">
                <Col span={24}>
                  <Title level={3} style={{ margin: 0, textAlign: "center" }}>
                    {shirt.shirtName} {t("sport-register-form-page.title-15")} {shirt.price}{" "}
                    {t("sport-register-form-page.title-16")}
                  </Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={11}>
                  <Image src={shirt.shirtImage} width="100%" />
                </Col>
                <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={13}>
                  <Title level={5} style={{ textAlign: "center" }}>
                    {t("sport-register-form-page.header-sizeShirt-2")}
                  </Title>
                  <Checkbox.Group onChange={(checkedValues) => handleCheckboxChange(checkedValues, index)}>
                    <Row gutter={[8, 8]} align="top" wrap>
                      {shirt.shirtSize.map((size, sizeIndex) => (
                        <React.Fragment key={sizeIndex}>
                          <Col xs={12} sm={6} md={6} lg={6} xl={5} xxl={5}>
                            <Checkbox value={size.split(" ")[0]}>{size}</Checkbox>
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6} xl={5} xxl={5}>
                            <Form.Item
                              name={`shirtQuantity_${shirt.name}_${size.split(" ")[0]}`}
                              rules={
                                selectedSizes[index]?.includes(size.split(" ")[0])
                                  ? [{ required: true, message: t("sport-register-form-page.title-72") }]
                                  : []
                              }
                            >
                              <InputNumber
                                min={1}
                                style={{ width: "100%" }}
                                onChange={(value) => handleQuantityChange(value, index, size.split(" ")[0])}
                                disabled={!selectedSizes[index]?.includes(size.split(" ")[0])}
                              />
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>
            </Card>
          ))}
        </Form>
        <Form form={form} layout="vertical">
          <Card
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "0 auto",
              borderRadius: "40px",
              padding: "20px 40px 20px 40px",
              marginTop: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Row justify="center" align="middle" gutter={[32, 32]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src={qrCodeData?.qrPayment1} />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src={qrCodeData?.qrPayment2} />
              </Col> */}
              <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={{ span: 6, offset: 2 }}>
                <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                  {t("sport-register-form-page.title-24")}
                </Title>
                <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                  {t("sport-register-form-page.title-25")}
                </Title>
                <Form.Item name="qrImage" layout="horizontal">
                  {fileList.length > 0 ? (
                    <div style={{ position: "relative" }}>
                      <Image src={URL.createObjectURL(fileList[0].originFileObj)} alt="Image preview" />
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={handleClearImage}
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          border: "none",
                        }}
                      />
                    </div>
                  ) : (
                    <Upload.Dragger
                      listType="picture"
                      name="files"
                      maxCount={1}
                      beforeUpload={beforeUpload}
                      onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                      showUploadList={false}
                      customRequest={() => {
                        /* no action required */
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <CameraOutlined />
                      </p>
                      <p className="ant-upload-text">{t("sport-register-form-page.title-26")}</p>
                    </Upload.Dragger>
                  )}
                </Form.Item>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text>{t("sport-register-form-page.title-73")}</Text>
                  </Col>
                  <Col>
                    <Text>
                      {totalShirtPrice} {t("sport-register-form-page.title-16")}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text>{t("sport-register-form-page.title-28")}</Text>
                  </Col>
                  <Col>
                    <Text>
                      {realDeliveryPrice} {t("sport-register-form-page.title-16")}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Title level={5} style={{ margin: 0, color: "#038416" }}>
                      {t("sport-register-form-page.title-30")}
                    </Title>
                  </Col>
                  <Col>
                    <Title level={5} style={{ margin: 0, color: "#038416" }}>
                      {totalPrice} {t("sport-register-form-page.title-16")}
                    </Title>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Form>
      </Card>
      <Flex justify="center" style={{ padding: "20px 0px 10px 0px" }}>
        <Button
          type="text"
          size="large"
          onClick={handleSubmit}
          loading={loading}
          style={{ backgroundColor: "#038416", color: "#FFFFFF" }}
        >
          {t("sport-register-form-page.button-send")}
        </Button>
      </Flex>
    </>
  );
};

export default FormOrderShirt;
