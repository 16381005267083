import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";
import { MoreOutlined } from "@ant-design/icons";
import { message, Button, Spin, Modal } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { createUser, fetchUserInfo } from "../services/fetch-events";
import { useAuth } from "../context/auth-context";

const SignInWithGoogle = ({ t, navigate }) => {
  const { setUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFacebook, setIsFacebook] = useState(false);
  const [isLine, setIsLine] = useState(false);

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isFb = userAgent.includes("FBAN") || userAgent.includes("FBAV");
    const isLn = userAgent.includes("Line");

    setIsFacebook(isFb);
    setIsLine(isLn);
  }, []);

  const warning = () => {
    Modal.warning({
      title: t("login-page.title-6"),
      content: (
        <div style={{ fontSize: "16px", lineHeight: "1.5" }}>
          {/* <p>{t("login-page.title-7")}</p> */}
          <p>
            {t("login-page.title-8")}
            <MoreOutlined style={{ fontSize: "24px", color: "#000000" }} /> {t("login-page.title-9")}
          </p>
          <img
            src="/gif-direct-to-chorm.gif" // URL ของ GIF
            alt="Loading Animation"
            style={{ width: "100%", maxWidth: "300px" }}
          />
        </div>
      ),
      onOk() {
        // อาจจะมีการดำเนินการเมื่อกดปุ่ม OK
      },
    });
  };

  const googleLogin = async () => {
    if (isFacebook || isLine) {
      warning(); // เรียกใช้ฟังก์ชัน warning
      return; // หยุดการทำงานของฟังก์ชัน
    }
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setLoading(true);

      if (user) {
        setUser(user);
        auth.onAuthStateChanged(async (user) => {
          const userData = {
            fullName: user?.displayName,
            email: user?.email,
            password: "",
            call: "",
            photo: user?.photoURL,
            userID: user?.uid,
            myEvents: {},
            providers: "gmail",
          };
          await createUser(userData);
        });
      }
    } catch (error) {
      message.error("เข้าสู่ระบบไม่สำเร็จ กรุณาลองอีกครั้ง", 2);
      console.error("Google login error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        type="link"
        block
        icon={loading ? <Spin size="small" /> : <GoogleOutlined />}
        size="large"
        onClick={googleLogin}
        style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", color: "#000000" }}
        disabled={loading} // ปิดปุ่มระหว่างโหลด
      >
        {loading ? "Logging in..." : "Google"}
      </Button>
    </>
  );
};

export default SignInWithGoogle;
