import React from "react";
import { Table, Typography } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ExcelDataTable = ({ excelData, events }) => {
  const hasTitle = events?.formDetail?.title;
  const hasAddress = events?.formDetail?.address;
  const hasBirthDate = events?.formDetail?.birthDate;
  const hasBloodGroup = events?.formDetail?.bloodGroup;
  const hasFirstName = events?.formDetail?.firstName;
  const hasNote = events?.formDetail?.note;
  const hasIdPass = events?.formDetail?.idPass;
  const hasLastName = events?.formDetail?.lastName;
  const hasOtherContact = events?.formDetail?.otherContact;
  // const hasOtherPhone = events?.formDetail?.otherPhone;
  const hasPhone = events?.formDetail?.phone;

  const columns = [
    ...(hasTitle
      ? [
          {
            title: "คำนำหน้า",
            dataIndex: "title",
            key: "title",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    ...(hasFirstName && hasLastName
      ? [
          {
            title: "ชื่อ-สกุล",
            key: "fullName",
            responsive: ["xs", "sm", "md", "lg"],
            render: (text, record) => (
              <span>
                {record.firstName} {record.lastName}
              </span>
            ),
          },
        ]
      : []),
    ...(hasPhone
      ? [
          {
            title: "เบอร์โทรศัพท์",
            dataIndex: "phone",
            key: "phone",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    ...(hasIdPass
      ? [
          {
            title: "เลขบัตรประชาชนหรือเลขพาสปอร์ต",
            dataIndex: "idPass",
            key: "idPass",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    ...(hasOtherContact
      ? [
          {
            title: "ช่องทางติดต่อออนไลน์ (Facebook/Line)",
            dataIndex: "otherContact",
            key: "otherContact",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    ...(hasBloodGroup
      ? [
          {
            title: "กรุ๊ปเลือด",
            dataIndex: "bloodGroup",
            key: "bloodGroup",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    // ...(hasOtherPhone
    //   ? [
    //       {
    //         title: "เบอร์โทรศัพท์ญาติ",
    //         dataIndex: "otherPhone",
    //         key: "otherPhone",
    //         responsive: ["xs", "sm", "md", "lg"],
    //       },
    //     ]
    //   : []),
    ...(hasNote
      ? [
          {
            title: "หมายเหตุถึงผู้จัดงาน",
            dataIndex: "note",
            key: "note",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    ...(hasBirthDate
      ? [
          {
            title: "วันเกิด",
            dataIndex: "birthDate",
            key: "birthDate",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    ...(hasAddress
      ? [
          {
            title: "ที่อยู่",
            dataIndex: "address",
            key: "address",
            responsive: ["xs", "sm", "md", "lg"],
          },
        ]
      : []),
    {
      title: "ขนาดเสื้อ",
      dataIndex: "shirtSize",
      key: "shirtSize",
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "ชื่อรุ่น",
      dataIndex: "raceOption",
      key: "raceOption",
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "รุ่นอายุ",
      dataIndex: "ageGroup",
      key: "ageGroup",
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "จัดส่งเสื้อ",
      dataIndex: "delivery",
      key: "delivery",
      responsive: ["xs", "sm", "md", "lg"],
      align: "center",
      render: (delivery) => (delivery ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: "ประกันชีวิต",
      dataIndex: "lifeInsurance",
      key: "lifeInsurance",
      responsive: ["xs", "sm", "md", "lg"],
      align: "center",
      render: (lifeInsurance) => (lifeInsurance ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: "รวมทั้งหมด",
      dataIndex: "totalPrice",
      key: "totalPrice",
      responsive: ["xs", "sm", "md", "lg"],
      render: (price, record, index) => {
        return index === 0 ? <Text>{price} บาท</Text> : null;
      },
    },
  ];

  return (
    <>
      <Typography.Text type="danger">
        กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง หากข้อมูลไม่แสดง กรุณายืนยันว่าท่านได้กรอกข้อมูลในใบสมัครทุกช่องครบถ้วน
      </Typography.Text>
      <Table
        columns={columns}
        dataSource={excelData}
        rowKey={(record) => record.firstName + record.lastName} // Unique row key, adjust based on your data
        pagination={false} // Disable pagination for simplicity
        scroll={{ x: 800 }} // Enable horizontal scroll on smaller screens
      />
    </>
  );
};

export default ExcelDataTable;
