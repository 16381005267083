import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Grid, Row } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import FormRegisterForFriend from "../../../../components/sport-register/sport-register-form-friend";
import FormRegisterSelf from "../../../../components/sport-register/sport-register-form-self";
import FormRegisterGroup from "../../../../components/sport-register/sport-register-form-group";
import ImageEventList from "../../../../components/sport-register/image-event-list";
import AppLayout from "../../../../layout/app-layout";
import "../../../../style/radio-button.css";
import ButtonMenuRow from "../../../../components/sport-register/button-menu-row";
import CategoryButton from "../../../../components/sport-register/category-button";
import MenuButton from "../../../../components/menu-button";
import FormControl from "../../../../components/sport-register/form-control";
import { useAuth } from "../../../../context/auth-context";
import { fetchQRCode, fetchRunEvents } from "../../../../services/fetch-events";
import RunningEventList from "../../../../components/sport-register/running-event-list";
import FormOrderShirt from "../../../../components/sport-register/sport-register-form-order-shirt";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;

const RegisterRunFormPage = ({}) => {
  const { user } = useAuth();

  const { id } = useParams();
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [loadingCard, setLoadingCard] = React.useState(false);
  const [formDataList, setFormDataList] = React.useState([{ raceOption: "funrun" }]);
  const [activeTabRegister, setActiveTabRegister] = React.useState("1");
  const [activeButton, setActiveButton] = React.useState("run");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [qrCodeData, setQrCodeData] = React.useState(null);
  const [eventClose, setEventClose] = React.useState(null);
  const [eventOpen, setEventOpen] = React.useState(null);

  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const { closeEventData, openEventData } = await fetchRunEvents("run", language); // เรียก API พร้อมพารามิเตอร์
        setEventClose(closeEventData);
        setEventOpen(openEventData);
        const fetchedQRCode = await fetchQRCode();
        setQrCodeData(fetchedQRCode);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  const filteredOpenEvents = eventOpen?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  const filteredCloseEvents = eventClose?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  const handleButtonClick = (type) => {
    setActiveButton(type);
    navigate(`/${language}/sport-register/${type}`);
  };

  const handleTabRegister = (tab) => {
    setActiveTabRegister(tab);
  };

  const buttonInCardStyles = [
    {
      backgroundColor: "#150E6E",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px 10px 0px 0px",
    }, // สมัคร
    {
      backgroundColor: "#FF9190",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px 10px 0px 0px",
    }, // สมัครให้เพื่อน
    {
      backgroundColor: "#2DB2AD",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      borderRadius: "10px 10px 0px 0px",
    }, // สมัครแบบกลุ่ม
  ];

  return (
    <AppLayout
      currentPage={`${t("app-header.title-1")} > ${t("app-header.title-2")} > ${t("app-header.title-5")}`}
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      handleNavigate={() => navigate("/")}
      screens={screens}
      user={user}
    >
      {/* <MenuButton screens={screens} navigate={navigate} isSportRegisterPage={true} /> */}
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl
            t={t}
            screens={screens}
            setSearchTerm={setSearchTerm}
            setSelectedProvince={setSelectedProvince}
            setSelectedMonth={setSelectedMonth}
          />
        </Row>
      </div>

      {searchTerm || selectedProvince || selectedMonth ? (
        <RunningEventList
          setSearchTerm={setSearchTerm}
          setSelectedProvince={setSelectedProvince}
          setSelectedMonth={setSelectedMonth}
          user={user}
          screens={screens}
          eventOpen={filteredOpenEvents}
          eventClose={filteredCloseEvents}
        />
      ) : (
        <>
          <ImageEventList t={t} eventId={id} />
          <ButtonMenuRow t={t} activeButton={activeButton} eventId={id} navigate={navigate} />
          {activeTabRegister === "1" ? (
            <FormRegisterSelf
              activeButton={activeButton}
              t={t}
              user={user}
              qrCodeData={qrCodeData}
              navigate={navigate}
              eventId={id}
              screens={screens}
              form={form}
              loading={loading}
              loadingCard={loadingCard}
              setLoading={setLoading}
              setLoadingCard={setLoadingCard}
              buttonInCardStyles={buttonInCardStyles}
              handleTabRegister={handleTabRegister}
            />
          ) : activeTabRegister === "2" ? (
            <FormRegisterForFriend
              activeButton={activeButton}
              t={t}
              user={user}
              qrCodeData={qrCodeData}
              navigate={navigate}
              eventId={id}
              screens={screens}
              form={form}
              formDataList={formDataList}
              setFormDataList={setFormDataList}
              loading={loading}
              loadingCard={loadingCard}
              setLoading={setLoading}
              setLoadingCard={setLoadingCard}
              buttonInCardStyles={buttonInCardStyles}
              handleTabRegister={handleTabRegister}
            />
          ) : activeTabRegister === "3" ? (
            <FormRegisterGroup
              activeButton={activeButton}
              t={t}
              user={user}
              qrCodeData={qrCodeData}
              navigate={navigate}
              eventId={id}
              screens={screens}
              form={form}
              formDataList={formDataList}
              setFormDataList={setFormDataList}
              loading={loading}
              loadingCard={loadingCard}
              setLoading={setLoading}
              setLoadingCard={setLoadingCard}
              buttonInCardStyles={buttonInCardStyles}
              handleTabRegister={handleTabRegister}
            />
          ) : activeTabRegister === "4" ? (
            <FormOrderShirt
              activeButton={activeButton}
              t={t}
              user={user}
              qrCodeData={qrCodeData}
              navigate={navigate}
              eventId={id}
              screens={screens}
              form={form}
              formDataList={formDataList}
              setFormDataList={setFormDataList}
              loading={loading}
              setLoading={setLoading}
              buttonInCardStyles={buttonInCardStyles}
              handleTabRegister={handleTabRegister}
            />
          ) : null}
        </>
      )}
    </AppLayout>
  );
};

export default RegisterRunFormPage;
