import React from "react";
import { Outlet } from "react-router-dom";
import { Spin } from "antd";
import { useAuth } from "../context/auth-context";
import LoginAdminPage from "../pages/login-admin-page";
import { Loading } from "../components/loading-spin";

const AdminRoutes = () => {
  const { userAdmin, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!userAdmin) {
    return <LoginAdminPage />;
  }

  return <Outlet />;
};

export default AdminRoutes;
