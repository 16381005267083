// นำเข้าฟังก์ชันที่คุณต้องการจาก Firebase SDK
import axios from "axios";
import { notification } from "antd";

export const loginUser = async (userData) => {
  try {
    // สร้าง URL พร้อมกับการส่งพารามิเตอร์ผ่าน query string
    const apiUrl = "https://login-user-hfrl7t3qfq-uc.a.run.app";
    const params = {
      email: userData.email,
      password: userData.password,
    };

    // ส่ง request ไปยัง API โดยใช้ axios
    const response = await axios.get(apiUrl, { params });

    // ตรวจสอบสถานะการตอบกลับ
    if (response.status === 200) {
      const userData = response.data;
      if (userData) {
        notification.success({
          message: "ล็อกอินสำเร็จ",
        });
        return userData;
      } else {
        console.error("User data not found");
      }
    } else {
      console.error(`Error: Received status code ${response.status}`);
    }
  } catch (error) {
    console.error("Error during login:", error);
    notification.error({
      message: "ล็อกอินไม่สำเร็จ",
      description: `Login failed: ${error.message}`,
    });
    return null;
  }
};

export const loginAdmin = async (email, password) => {
  try {
    // สร้าง URL พร้อมกับการส่งพารามิเตอร์ผ่าน query string
    const apiUrl = "https://login-admin-hfrl7t3qfq-uc.a.run.app";
    const params = {
      email,
      password,
    };

    // ส่ง request ไปยัง API โดยใช้ axios
    const response = await axios.get(apiUrl, { params });

    // ตรวจสอบสถานะการตอบกลับ
    if (response.status === 200) {
      const userData = response.data;
      if (userData) {
        notification.success({
          message: "ล็อกอินสำเร็จ",
        });
        return userData;
      } else {
        console.error("User data not found");
      }
    } else {
      console.error(`Error: Received status code ${response.status}`);
    }
  } catch (error) {
    console.error("Error during login:", error);
    notification.error({
      message: "ล็อกอินไม่สำเร็จ",
      description: `Login failed: ${error.message}`,
    });
    return null;
  }
};

export const createUser = async (userData) => {
  try {
    const apiUrl = "https://create-user-hfrl7t3qfq-uc.a.run.app";

    // Sending request to the API
    const response = await axios.post(apiUrl, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check the response status
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error adding new user:", error);
    return null;
  }
};

export const editUser = async (userData) => {
  try {
    const apiUrl = "https://edit-user-hfrl7t3qfq-uc.a.run.app";

    const response = await axios.post(apiUrl, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check the response status
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error adding new user:", error);
    return null;
  }
};

export const fetchMyRegisterEvents = async (uid, email, lang) => {
  try {
    const response = await axios.get("https://get-my-register-hfrl7t3qfq-uc.a.run.app", {
      params: { uid, email, lang },
    });

    if (response.status === 200) {
      const data = response.data || {}; // Ensure data is not null

      const closeEvents = data.close || {}; // Default to empty object if undefined
      const openEvents = data.open || {}; // Default to empty object if undefined

      // Map events safely
      const closeEventData = Object.entries(closeEvents).map(([id, eventData]) => {
        const members = eventData.member ? Object.values(eventData.member) : [];
        const orderShirt = eventData.shirtOrder ? Object.values(eventData.shirtOrder) : [];

        return {
          id,
          ...eventData,
          member: members,
          shirtOrder: orderShirt,
        };
      });

      const openEventData = Object.entries(openEvents).map(([id, eventData]) => {
        const members = eventData.member ? Object.values(eventData.member) : [];
        const orderShirt = eventData.shirtOrder ? Object.values(eventData.shirtOrder) : [];

        return {
          id,
          ...eventData,
          member: members,
          shirtOrder: orderShirt,
        };
      });

      // Always return an object with the expected keys
      return { closeEventData, openEventData };
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return { closeEventData: [], openEventData: [] }; // Fallback with empty arrays
    }
  } catch (error) {
    console.error("Error fetching registration information:", error);
    return { closeEventData: [], openEventData: [] }; // Fallback with empty arrays on error
  }
};

export const fetchUserInfo = async (email) => {
  try {
    const apiUrl = "https://get-user-infor-hfrl7t3qfq-uc.a.run.app";

    const response = await axios.get(apiUrl, {
      params: { email: email },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data; // Return the user data
    } else {
      // console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    // console.error("Error fetching user information:", error);
    return null;
  }
};

export const fetchAdminInfo = async (email) => {
  try {
    const apiUrl = "https://get-admin-infor-hfrl7t3qfq-uc.a.run.app";

    const response = await axios.get(apiUrl, {
      params: { email: email },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data; // Return the user data
    } else {
      // console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    // console.error("Error fetching user information:", error);
    return null;
  }
};

export const fetchQRCode = async () => {
  try {
    const apiUrl = "https://get-qr-hfrl7t3qfq-uc.a.run.app";

    // ส่ง request ไปยัง API
    const response = await axios.get(apiUrl);

    // ตรวจสอบสถานะการตอบกลับ
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Error fetching QR code:", error);
    return null;
  }
};

export const fetchRunEvents = async (eventType, lang) => {
  try {
    const response = await axios.get("https://get-events-admin-hfrl7t3qfq-uc.a.run.app", {
      params: { lang, eventType },
    });
    if (response.status === 200) {
      const data = response.data;

      // แยกข้อมูลเป็น close และ open
      const closeEventData = Object.entries(data.close).map(([id, eventData]) => {
        // ตรวจสอบว่ามี member หรือไม่ ถ้าไม่มีให้สร้าง member เป็น object เปล่า
        const members = eventData.member ? Object.values(eventData.member) : [];
        const orderShirt = eventData.shirtOrder ? Object.values(eventData.shirtOrder) : [];

        return {
          id,
          ...eventData,
          member: members,
          shirtOrder: orderShirt,
        };
      });

      const openEventData = Object.entries(data.open).map(([id, eventData]) => {
        // ตรวจสอบว่ามี member หรือไม่ ถ้าไม่มีให้สร้าง member เป็น object เปล่า
        const members = eventData.member ? Object.values(eventData.member) : [];
        const orderShirt = eventData.shirtOrder ? Object.values(eventData.shirtOrder) : [];

        return {
          id,
          ...eventData,
          member: members,
          shirtOrder: orderShirt,
        };
      });

      // ส่งคืนข้อมูลในรูปแบบที่ต้องการ
      return { closeEventData, openEventData };
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Error fetching events:", error);
  }
};

export const fetchEventById = async (eventId, lang) => {
  try {
    const response = await axios.get("https://get-events-id-hfrl7t3qfq-uc.a.run.app", {
      params: { ID: eventId, lang },
    });

    if (response.status === 200) {
      const eventData = response.data;

      // ตรวจสอบว่ามี member หรือไม่ ถ้าไม่มีให้สร้าง member เป็น object เปล่า
      const members = eventData.member ? Object.values(eventData.member) : [];
      const orderShirt = eventData.shirtOrder ? Object.values(eventData.shirtOrder) : [];

      return {
        eventData: {
          ...eventData,
          member: eventData.member || {},
          shirtOrder: eventData.shirtOrder || {},
        },
        members,
        orderShirt,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching event by ID:", error);
    return null;
  }
};

export const postImageSlip = async (fileName, file) => {
  try {
    const apiUrl = "https://upload-slip-hfrl7t3qfq-uc.a.run.app";

    const params = {
      file: file,
      fileName: fileName,
    };

    const response = await axios.post(apiUrl, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error uploading image slip:", error);
    return null;
  }
};

export const pushNewMember = async (type, eventId, memberData) => {
  try {
    const apiUrl = "https://push-new-member-hfrl7t3qfq-uc.a.run.app";

    // Parameters ที่จะส่ง
    const params = {
      Type: type,
      ID: eventId,
      member: memberData,
    };

    // เรียก API โดยส่ง params ไปพร้อมกับคำขอ
    const response = await axios.post(apiUrl, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // ตรวจสอบการตอบกลับ
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error("Error pushing new member:", error);
    return error;
  }
};

export const pushNewOrderShirt = async (type, eventId, orderData) => {
  try {
    const apiUrl = "https://push-new-order-hfrl7t3qfq-uc.a.run.app";

    // Parameters ที่จะส่ง
    const params = {
      Type: type,
      ID: eventId,
      shirtOrder: orderData,
    };

    // เรียก API โดยส่ง params ไปพร้อมกับคำขอ
    const response = await axios.post(apiUrl, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // ตรวจสอบการตอบกลับ
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Error:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error pushing new member:", error);
    return null;
  }
};

export const updateMember = async (type, eventId, memberData) => {
  try {
    const apiUrl = "https://set-member-hfrl7t3qfq-uc.a.run.app";

    // Construct the params for the request
    const params = {
      Type: type,
      ID: eventId,
      member: memberData,
    };

    // Send the POST request to the API
    const response = await axios.post(apiUrl, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check the response status
    if (response.status === 200) {
      return response.data; // Return the data from the response
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Error setting member:", error);
    return null; // Return null in case of error
  }
};

export const updateOrderShirt = async (type, eventId, orderData) => {
  try {
    const apiUrl = "https://set-order-hfrl7t3qfq-uc.a.run.app";

    // Construct the params for the request
    const params = {
      Type: type,
      ID: eventId,
      shirtOrder: orderData,
    };

    // Send the POST request to the API
    const response = await axios.post(apiUrl, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check the response status
    if (response.status === 200) {
      return response.data; // Return the data from the response
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Error setting member:", error);
    return null; // Return null in case of error
  }
};

export const deleteMember = async (type, eventId, memberId, refAcc) => {
  try {
    const apiUrl = "https://delete-member-hfrl7t3qfq-uc.a.run.app";

    // Construct the params for the request
    const params = {
      Type: type,
      ID: eventId,
      memberID: memberId,
      refAcc: refAcc,
    };

    // Send the POST request to the API
    const response = await axios.delete(apiUrl, {
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check the response status
    if (response.status === 200) {
      return response.data; // Return the data from the response
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Error setting member:", error);
    return null; // Return null in case of error
  }
};

export const deleteOrderShirt = async (type, eventId, orderData, refAcc) => {
  try {
    const apiUrl = "https://delete-order-hfrl7t3qfq-uc.a.run.app";

    // Construct the params for the request
    const params = {
      Type: type,
      ID: eventId,
      shirtOrderID: orderData,
      refAcc: refAcc,
    };

    // Send the POST request to the API
    const response = await axios.delete(apiUrl, {
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check the response status
    if (response.status === 200) {
      return response.data; // Return the data from the response
    } else {
      console.error(`Error: Received status code ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Error setting member:", error);
    return null; // Return null in case of error
  }
};
