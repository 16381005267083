import React from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Space,
  Descriptions,
  Divider,
  Collapse,
  Input,
  Select,
  Grid,
  Spin,
} from "antd";
import {
  CalendarOutlined,
  MailOutlined,
  EnvironmentOutlined,
  UserOutlined,
  PhoneOutlined,
  CheckOutlined,
  HomeOutlined,
  SkinOutlined,
  SolutionOutlined,
  TeamOutlined,
  TrophyOutlined,
  GiftOutlined,
  TruckOutlined,
  LoadingOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { fetchMyRegisterEvents, updateMember } from "../../services/fetch-events";
import { useAuth } from "../../context/auth-context";
import AppLayoutLogin from "../../layout/app-layout-login";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/app-provider";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const EventDetail = ({}) => {
  const { user } = useAuth();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [loadingCard, setLoadingCard] = React.useState(false);
  const [editingMemberId, setEditingMemberId] = React.useState(null);
  const [currentEventId, setCurrentEventId] = React.useState(null);
  const [memberData, setMemberData] = React.useState({});
  const [eventClose, setEventClose] = React.useState([]);
  const [eventOpen, setEventOpen] = React.useState([]);
  const [eventName, setEventName] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      const { closeEventData, openEventData } = await fetchMyRegisterEvents(user?.uid, user?.email, language);

      if (closeEventData) {
        setEventClose(closeEventData);
      }

      if (openEventData) {
        setEventOpen(openEventData);
      }
    };

    fetchData();
  }, [eventClose, eventOpen, user?.uid, user?.email, language]);

  const mapShirtSize = (sizeKey) => {
    let displaySize = sizeKey; // ค่าเริ่มต้นเป็น sizeKey

    switch (sizeKey) {
      case "XXL":
        displaySize = "2XL";
        break;
      case "XXXL":
        displaySize = "3XL";
        break;
      case "XXXXL":
        displaySize = "4XL";
        break;
      case "XXXXXL":
        displaySize = "5XL";
        break;
      case "XXS":
        displaySize = "2XS";
        break;
      default:
        break;
    }

    return displaySize;
  };

  const handleEdit = (eventId, memberId, member) => {
    if (eventId && memberId) {
      // Extract the first character of eventId (either "r" or "b")
      const eventPrefix = eventId.charAt(0);

      // Set the event name based on the prefix
      if (eventPrefix === "r") {
        setEventName("run");
      } else if (eventPrefix === "b") {
        setEventName("bicycle");
      }

      setEditingMemberId(memberId);
      setCurrentEventId(eventId);
      setMemberData(member); // กำหนดข้อมูลสมาชิกที่ต้องการแก้ไข
    }
  };

  const handleSave = async (eventId, memberId) => {
    setLoading(true);
    try {
      const updatedMemberData = {
        date: memberData.date,
        time: memberData.time,
        uid: user?.uid,
        title: memberData.title,
        address: memberData.address,
        ageGroup: memberData.ageGroup,
        birthDate: memberData.birthDate,
        delivery: memberData.delivery,
        firstName: memberData.firstName,
        lastName: memberData.lastName,
        note: memberData.note,
        idPass: memberData.idPass,
        otherContact: memberData.otherContact,
        bloodGroup: memberData.bloodGroup,
        otherPhone: memberData.otherPhone,
        qrImage: memberData.qrImage,
        lifeInsurance: memberData.lifeInsurance,
        phone: memberData.phone,
        raceOption: memberData.raceOption,
        shirtSize: memberData.shirtSize,
        totalPrice: memberData.totalPrice,
        status: "pending",
        refAcc: user?.email,
        groupName: memberData.groupName,
      };

      // Save the data for the specific member
      await updateMember(`${eventName}`, eventId, [{ ...updatedMemberData }]);
      setEventOpen((prevEvents) =>
        prevEvents.map((event) => {
          if (event.id === eventId) {
            return {
              ...event,
              member: {
                ...event.member,
                [memberId]: {
                  ...event.member[memberId],
                  ...updatedMemberData, // Update member data with new values
                },
              },
            };
          }
          return event;
        })
      );
      setEditingMemberId(null); // Exit editing mode for the member
    } catch (error) {
      console.error("Save failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setMemberData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <AppLayoutLogin
      currentPage={`${t("app-header.title-6")} > ${t("app-header.title-7")}`}
      screens={screens}
      GetIconNav={<UserOutlined style={{ fontSize: "24px" }} />}
    >
      <Row
        justify="space-around"
        align="middle"
        style={{
          backgroundImage: `url("/main-banner.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          borderRadius: 20,
          padding: "20px 0px",
        }}
      >
        {loadingCard ? (
          <Spin
            size="large"
            indicator={<LoadingOutlined style={{ fontSize: 48, display: "block", margin: "20px auto" }} spin />}
          />
        ) : (
          <>
            <Col span={24}>
              {eventOpen?.length > 0 && eventOpen[0]?.member?.length > 0 ? (
                eventOpen?.map((event) => (
                  <Card
                    key={event.id}
                    type="inner"
                    title={t("my-event-page.title-24")}
                    cover={
                      <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                        <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
                      </div>
                    }
                    style={{
                      maxWidth: "768px",
                      margin: "0 auto",
                      marginBottom: "16px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                  >
                    <Card.Meta
                      title={event?.Title}
                      description={`${t("sport-register-page.end-event")} ${event?.end}`}
                      style={{ paddingBottom: "10px" }}
                    />
                    <Row>
                      <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                        <Paragraph style={{ margin: 0 }}>
                          <EnvironmentOutlined style={{ color: "red" }} /> {event.location} <CalendarOutlined />{" "}
                          {event.date}
                        </Paragraph>
                      </Col>
                    </Row>

                    <Collapse style={{ marginTop: "16px" }} expandIconPosition="end">
                      <Panel header={t("my-event-page.title-25")} key="1">
                        {Object.entries(event.member).map(([memberId, member]) => (
                          <React.Fragment key={memberId}>
                            <Space direction="vertical" size="large" style={{ width: "100%", marginTop: "16px" }}>
                              <Descriptions layout="vertical" column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}>
                                {member.date ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <CalendarOutlined />
                                        <span>{t("my-event-page.title-6")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.date} {member.time}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.title ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-7")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.title}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.firstName ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-8")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.firstName}
                                        onChange={(e) => handleChange("firstName", e.target.value)}
                                      />
                                    ) : (
                                      member.firstName
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.lastName ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-9")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.lastName}
                                        onChange={(e) => handleChange("lastName", e.target.value)}
                                      />
                                    ) : (
                                      member.lastName
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.birthDate ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <GiftOutlined />
                                        <span>{t("my-event-page.title-10")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.birthDate}
                                        onChange={(e) => handleChange("birthDate", e.target.value)}
                                      />
                                    ) : (
                                      member.birthDate
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.phone ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <PhoneOutlined />
                                        <span>{t("my-event-page.title-11")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.phone}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.address ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <HomeOutlined />
                                        <span>{t("my-event-page.title-12")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.address}
                                        onChange={(e) => handleChange("address", e.target.value)}
                                      />
                                    ) : (
                                      member.address
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.idPass ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-13")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.idPass}
                                        onChange={(e) => handleChange("idPass", e.target.value)}
                                      />
                                    ) : (
                                      member.idPass
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.otherContact ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-14")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.otherContact}
                                        onChange={(e) => handleChange("otherContact", e.target.value)}
                                      />
                                    ) : (
                                      member.otherContact
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.bloodGroup ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <SnippetsOutlined />
                                        <span>{t("my-event-page.title-15")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Select
                                        value={memberData.bloodGroup}
                                        onChange={(value) => handleChange("bloodGroup", value)}
                                        style={{ width: "100%" }}
                                      >
                                        <Select.Option value="A">A</Select.Option>
                                        <Select.Option value="B">B</Select.Option>
                                        <Select.Option value="AB">AB</Select.Option>
                                        <Select.Option value="O">O</Select.Option>
                                      </Select>
                                    ) : (
                                      member.bloodGroup
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.otherPhone ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <PhoneOutlined />
                                        <span>{t("my-event-page.title-16")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.otherPhone}
                                        onChange={(e) => handleChange("otherPhone", e.target.value)}
                                      />
                                    ) : (
                                      member.otherPhone
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {/* Shirt Size */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <SkinOutlined />
                                      <span>{t("my-event-page.title-17")}</span>
                                    </Space>
                                  }
                                >
                                  {editingMemberId === memberId && currentEventId === event.id ? (
                                    <Select
                                      value={memberData.shirtSize}
                                      onChange={(value) => handleChange("shirtSize", value)}
                                    >
                                      {member.title === t("my-event-page.title-26")
                                        ? Object.entries(
                                            event.registerDetail[memberData.raceOption]?.shirtSizeMale || {}
                                          )
                                            .sort(([, aChest], [, bChest]) => {
                                              const chestA = parseInt(aChest.split(" ")[1], 10);
                                              const chestB = parseInt(bChest.split(" ")[1], 10);
                                              return chestA - chestB;
                                            })
                                            .map(([sizeKey]) => (
                                              <Select.Option key={sizeKey} value={sizeKey}>
                                                {sizeKey}
                                              </Select.Option>
                                            ))
                                        : member.title === t("my-event-page.title-27") ||
                                          member.title === t("my-event-page.title-28")
                                        ? Object.entries(
                                            event.registerDetail[memberData.raceOption]?.shirtSizeFemale || {}
                                          )
                                            .sort(([, aChest], [, bChest]) => {
                                              const chestA = parseInt(aChest.split(" ")[1], 10);
                                              const chestB = parseInt(bChest.split(" ")[1], 10);
                                              return chestA - chestB;
                                            })
                                            .map(([sizeKey]) => (
                                              <Select.Option key={sizeKey} value={sizeKey}>
                                                {sizeKey}
                                              </Select.Option>
                                            ))
                                        : null}
                                    </Select>
                                  ) : (
                                    member.shirtSize
                                  )}
                                </Descriptions.Item>

                                {/* Age Group */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TrophyOutlined />
                                      <span>{t("my-event-page.title-18")}</span>
                                    </Space>
                                  }
                                >
                                  {editingMemberId === memberId && currentEventId === event.id ? (
                                    <Select
                                      value={memberData.ageGroup}
                                      onChange={(value) => handleChange("ageGroup", value)}
                                    >
                                      {/* Check the title and choose the appropriate detail */}
                                      {member.title === t("my-event-page.title-26")
                                        ? event.registerDetail[member.raceOption]?.typeDetailMale.map((type) => (
                                            <Select.Option key={type} value={type}>
                                              {type}
                                            </Select.Option>
                                          ))
                                        : member.title === t("my-event-page.title-27") ||
                                          member.title === t("my-event-page.title-28")
                                        ? event.registerDetail[member.raceOption]?.typeDetailFemale.map((type) => (
                                            <Select.Option key={type} value={type}>
                                              {type}
                                            </Select.Option>
                                          ))
                                        : null}
                                    </Select>
                                  ) : (
                                    member.ageGroup
                                  )}
                                </Descriptions.Item>
                                {/* Race Option */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TrophyOutlined />
                                      <span>{t("my-event-page.title-19")}</span>
                                    </Space>
                                  }
                                >
                                  {member.raceOption}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TeamOutlined />
                                      <span>{t("my-event-page.title-20")}</span>
                                    </Space>
                                  }
                                >
                                  {editingMemberId === memberId && currentEventId === event.id ? (
                                    <Input
                                      value={memberData.groupName}
                                      onChange={(e) => handleChange("groupName", e.target.value)}
                                    />
                                  ) : member.groupName !== "" ? (
                                    member.groupName
                                  ) : (
                                    "-"
                                  )}
                                </Descriptions.Item>
                                {member.note ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <MailOutlined />
                                        <span>{t("sport-register-form-page.title-76")}</span>
                                      </Space>
                                    }
                                  >
                                    {editingMemberId === memberId && currentEventId === event.id ? (
                                      <Input
                                        value={memberData.note}
                                        onChange={(e) => handleChange("note", e.target.value)}
                                      />
                                    ) : (
                                      member.note
                                    )}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}

                                {/* Delivery Status */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TruckOutlined />
                                      <span>{t("my-event-page.title-21")}</span>
                                    </Space>
                                  }
                                >
                                  {member.delivery ? t("my-event-page.title-29") : t("my-event-page.title-30")}
                                </Descriptions.Item>
                                {/* Life Insurance */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <SolutionOutlined />
                                      <span>{t("my-event-page.title-22")}</span>
                                    </Space>
                                  }
                                >
                                  {member.lifeInsurance ? t("my-event-page.title-31") : t("my-event-page.title-32")}
                                </Descriptions.Item>
                                {/* Payment Status */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <CheckOutlined />
                                      <span>{t("my-event-page.title-23")}</span>
                                    </Space>
                                  }
                                >
                                  {member.status === "paid"
                                    ? t("my-event-page.title-33")
                                    : member.status === "pending"
                                    ? t("my-event-page.title-34")
                                    : member.status === "cancel"
                                    ? t("my-event-page.title-35")
                                    : t("my-event-page.title-36")}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                            <Space justify="end" style={{ marginTop: "16px" }}>
                              {editingMemberId === memberId && currentEventId === event.id ? (
                                <>
                                  <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={() => handleSave(event.id, memberId)}
                                  >
                                    {t("my-event-page.button-2")}
                                  </Button>
                                  <Button type="default" onClick={() => setEditingMemberId(null)} disabled={loading}>
                                    {t("my-event-page.button-3")}
                                  </Button>
                                </>
                              ) : (
                                <Button type="primary" onClick={() => handleEdit(event.id, memberId, member)}>
                                  {t("my-event-page.button-1")}
                                </Button>
                              )}
                            </Space>
                            <Divider style={{ borderColor: "#130C63" }} />
                          </React.Fragment>
                        ))}
                      </Panel>
                    </Collapse>
                  </Card>
                ))
              ) : (
                <Card
                  title={t("my-event-page.title-1")}
                  extra={
                    <Button onClick={() => navigate(`/${language}/sport-register/run`)}>
                      {t("my-event-page.title-2")}
                    </Button>
                  }
                  style={{
                    maxWidth: "300px",
                    margin: "0 auto",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  }}
                >
                  {t("my-event-page.title-3")}
                </Card>
              )}
            </Col>
            <Col span={24}>
              {eventClose?.length > 0 && eventClose[0]?.member?.length > 0 ? (
                eventClose?.map((event) => (
                  <Card
                    key={event.id}
                    type="inner"
                    title={t("my-event-page.title-4")}
                    cover={
                      <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                        <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
                      </div>
                    }
                    style={{
                      maxWidth: "768px",
                      margin: "0 auto",
                      marginBottom: "16px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                  >
                    <Card.Meta
                      title={event?.Title}
                      description={`${t("sport-register-page.end-event")} ${event?.end}`}
                      style={{ paddingBottom: "10px" }}
                    />
                    <Row>
                      <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                        <Paragraph style={{ margin: 0 }}>
                          <EnvironmentOutlined style={{ color: "red" }} /> {event.location} <CalendarOutlined />{" "}
                          {event.date}
                        </Paragraph>
                      </Col>
                    </Row>

                    <Collapse style={{ marginTop: "16px" }} expandIconPosition="end">
                      <Panel header={t("my-event-page.title-25")} key="2">
                        {Object.entries(event.member).map(([memberId, member]) => (
                          <React.Fragment key={memberId}>
                            <Space direction="vertical" size="large" style={{ width: "100%", marginTop: "16px" }}>
                              <Descriptions layout="vertical" column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}>
                                {member.date ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <CalendarOutlined />
                                        <span>{t("my-event-page.title-6")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.date} {member.time}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.title ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-7")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.title}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.firstName ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-8")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.firstName}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.lastName ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-9")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.lastName}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.birthDate ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <GiftOutlined />
                                        <span>{t("my-event-page.title-10")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.birthDate}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.phone ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <PhoneOutlined />
                                        <span>{t("my-event-page.title-11")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.phone}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.address ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <HomeOutlined />
                                        <span>{t("my-event-page.title-12")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.address}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.idPass ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <HomeOutlined />
                                        <span>{t("my-event-page.title-13")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.idPass}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.otherContact ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <UserOutlined />
                                        <span>{t("my-event-page.title-14")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.otherContact}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.bloodGroup ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <SnippetsOutlined />
                                        <span>{t("my-event-page.title-15")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.bloodGroup}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                {member.otherPhone ? (
                                  <Descriptions.Item
                                    label={
                                      <Space>
                                        <PhoneOutlined />
                                        <span>{t("my-event-page.title-16")}</span>
                                      </Space>
                                    }
                                  >
                                    {member.otherPhone}
                                  </Descriptions.Item>
                                ) : (
                                  <></>
                                )}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <SkinOutlined />
                                      <span>{t("my-event-page.title-17")}</span>
                                    </Space>
                                  }
                                >
                                  {mapShirtSize(member.shirtSize)}
                                </Descriptions.Item>
                                {/* Age Group */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TrophyOutlined />
                                      <span>{t("my-event-page.title-18")}</span>
                                    </Space>
                                  }
                                >
                                  {member.ageGroup}
                                </Descriptions.Item>
                                {/* Race Option */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TrophyOutlined />
                                      <span>{t("my-event-page.title-19")}</span>
                                    </Space>
                                  }
                                >
                                  {member.raceOption}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TeamOutlined />
                                      <span>{t("my-event-page.title-20")}</span>
                                    </Space>
                                  }
                                >
                                  {member.groupName !== "" ? member.groupName : "-"}
                                </Descriptions.Item>
                                {/* Delivery Status */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <TruckOutlined />
                                      <span>{t("my-event-page.title-21")}</span>
                                    </Space>
                                  }
                                >
                                  {member.delivery ? t("my-event-page.title-29") : t("my-event-page.title-30")}
                                </Descriptions.Item>
                                {/* Life Insurance */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <SolutionOutlined />
                                      <span>{t("my-event-page.title-22")}</span>
                                    </Space>
                                  }
                                >
                                  {member.lifeInsurance ? t("my-event-page.title-31") : t("my-event-page.title-32")}
                                </Descriptions.Item>
                                {/* Payment Status */}
                                <Descriptions.Item
                                  label={
                                    <Space>
                                      <CheckOutlined />
                                      <span>{t("my-event-page.title-23")}</span>
                                    </Space>
                                  }
                                >
                                  {member.status === "paid"
                                    ? t("my-event-page.title-33")
                                    : member.status === "pending"
                                    ? t("my-event-page.title-34")
                                    : member.status === "cancel"
                                    ? t("my-event-page.title-35")
                                    : t("my-event-page.title-36")}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                            <Divider style={{ borderColor: "#130C63" }} />
                          </React.Fragment>
                        ))}
                      </Panel>
                    </Collapse>
                  </Card>
                ))
              ) : (
                <Card
                  title="รายการที่เสร็จสิ้นแล้ว"
                  style={{
                    maxWidth: "300px",
                    margin: "0 auto",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  }}
                >
                  {t("my-event-page.title-5")}
                </Card>
              )}
            </Col>
          </>
        )}
      </Row>
    </AppLayoutLogin>
  );
};

export default EventDetail;
