// src/components/ButtonRow.jsx
import React from "react";
import { Row, Col, Button, Space, Typography } from "antd";
import { fetchEventById } from "../../services/fetch-events";
import dayjs from "dayjs";
import { Loading } from "../loading-spin";
import { useAppContext } from "../../context/app-provider";

const { Text } = Typography;

const buttonStyles = [
  {
    backgroundColor: "#150E6E",
    width: "100%",
    height: "100%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px 10px 0px 0px",
  }, // สมัคร
  {
    backgroundColor: "#5E72EB",
    width: "100%",
    height: "100%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px 10px 0px 0px",
  }, // เช็ครายชื่อผู้สมัคร
  {
    backgroundColor: "#EFE26A",
    width: "100%",
    height: "100%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px 10px 0px 0px",
  }, // รายละเอียด
];

const ButtonMenuRow = ({ t, activeButton, eventId, navigate }) => {
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const parseDate = (dateString) => dayjs(dateString, "DD/MM/YYYY");
  const { language } = useAppContext();

  const isRegistrationClosed = events?.end ? dayjs().isAfter(parseDate(events.end), "day") : false;

  React.useEffect(() => {
    const getEventData = async () => {
      const { eventData } = await fetchEventById(eventId, language);
      setEvents(eventData);
      setLoading(false);
    };

    getEventData();
  }, [eventId, language]);

  const handleRegisterClick = (eventId) => {
    navigate(`/${language}/sport-register/${activeButton}/register/${eventId}`);
  };

  const handleCheckMemberClick = (eventId) => {
    navigate(`/${language}/sport-register/${activeButton}/member/${eventId}`);
  };

  const handleDetailClick = (eventId) => {
    navigate(`/${language}/sport-register/${activeButton}/detail/${eventId}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Row gutter={[16, 32]} style={{ margin: "20px 0px 0px 0px" }}>
      <Col xs={7} sm={8} md={4} lg={4} xl={3} xxl={3}>
        <Button
          type="text"
          style={buttonStyles[0]}
          onClick={() => handleRegisterClick(eventId)}
          disabled={isRegistrationClosed} // disable ปุ่มถ้าการสมัครหมดเขต
        >
          <Space direction="vertical" align="center">
            <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
              {isRegistrationClosed ? t("sport-register-page.end-event") : t("sport-register-page.button-card-event-4")}{" "}
            </Text>
          </Space>
        </Button>
      </Col>
      <Col xs={9} sm={8} md={5} lg={5} xl={3} xxl={3}>
        <Button type="text" style={buttonStyles[1]} onClick={() => handleCheckMemberClick(eventId)}>
          <Space direction="vertical" align="center">
            <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
              {t("sport-register-page.button-card-event-3")}
            </Text>
          </Space>
        </Button>
      </Col>
      <Col xs={8} sm={8} md={4} lg={4} xl={3} xxl={3}>
        <Button type="text" style={buttonStyles[2]}>
          <Space direction="vertical" align="center">
            <Text strong style={{ margin: 0 }} onClick={() => handleDetailClick(eventId)}>
              {t("sport-register-page.button-card-event-1")}
            </Text>
          </Space>
        </Button>
      </Col>
    </Row>
  );
};

export default ButtonMenuRow;
