import React from "react";
import { Button, Col, Image, Modal, Row, Typography, Spin, Alert } from "antd";
import { CalendarOutlined, EnvironmentOutlined } from "@ant-design/icons";

import "../../style/image-gallery.css";
import { Loading } from "../loading-spin";
import { fetchEventById } from "../../services/fetch-events";
import { useAppContext } from "../../context/app-provider";

const { Title } = Typography;

const ImageEventList = ({ t, eventId }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { language } = useAppContext();
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const images = events?.imageEtc || [];

  React.useEffect(() => {
    const getEventData = async () => {
      const { eventData } = await fetchEventById(eventId, language);
      setEvents(eventData);
      setLoading(false);
    };

    getEventData();
  }, [eventId, language]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ padding: "1% 5%" }}>
      <Row gutter={[16, 32]} justify="center" align="middle">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ textAlign: "center" }}>
          <Image preview={false} src={events?.cover} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Row gutter={[8, 8]}>
            {images.slice(0, 3).map((src, index) => (
              <Col span={12} key={index}>
                <Image preview={false} src={src} />
              </Col>
            ))}
            <Col span={12}>
              {images.length > 3 && (
                <div className="image-overlay-container" onClick={showModal}>
                  <Image preview={false} src={images[3]} className="image-with-overlay" />
                  <div className="overlay-text">+{images.length - 3}</div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ backgroundColor: "#D9D9D9", padding: 5 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Title level={4} style={{ margin: 0 }}>
            {events?.Title}
          </Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Title level={5} style={{ margin: 0 }}>
            {t("sport-register-page.title-1")} <CalendarOutlined /> {events?.date}
          </Title>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ backgroundColor: "rgba(108, 108, 108, 0.1)", padding: 5 }}>
        <Col span={12}>
          <EnvironmentOutlined style={{ color: "red", padding: "0 5px" }} />
          {events?.location}
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            href={events?.locationMap}
            target="_blank"
            size="small"
            style={{ backgroundColor: "#2DB2AD", color: "#4C4C4C", borderRadius: 20 }}
          >
            Google Maps <EnvironmentOutlined style={{ color: "red" }} /> {t("sport-register-page.title-2")}
          </Button>
        </Col>
      </Row>
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null} width={800}>
        <Row gutter={[8, 8]}>
          {images.map((src, index) => (
            <Col span={12} key={index}>
              <Image src={src} />
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default ImageEventList;
