import React from "react";
import {
  Form,
  Input,
  Card,
  Row,
  Col,
  Modal,
  Radio,
  Button,
  Checkbox,
  Typography,
  notification,
  Image,
  Upload,
  Select,
  Space,
  Flex,
} from "antd";
import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import { fetchEventById, postImageSlip, pushNewMember } from "../../services/fetch-events";
import dayjs from "dayjs";
import { useAppContext } from "../../context/app-provider";
import { Loading, OverlayLoading } from "../loading-spin";

const { Text, Title } = Typography;

const FormRegisterSelf = ({
  activeButton,
  t,
  user,
  navigate,
  qrCodeData,
  eventId,
  screens,
  form,
  loading,
  loadingCard,
  setLoading,
  setLoadingCard,
  buttonInCardStyles,
  handleTabRegister,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState({
    delivery: false,
    lifeInsurance: false,
  });
  const [events, setEvents] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("");
  const [fileList, setFileList] = React.useState([]);
  const [ageGroupOptions, setAgeGroupOptions] = React.useState([]);
  const [shirtSizeOptions, setShirtSizeOptions] = React.useState([]);
  const [raceOptions, setRaceOptions] = React.useState([]);
  const selectedRaceOption = raceOptions.find((option) => option.key === activeTab);
  const [selectedRace, setSelectedRace] = React.useState(raceOptions?.[0]?.key);
  const defaultRaceOption = raceOptions?.[0]?.key;
  const { language } = useAppContext();

  React.useEffect(() => {
    if (events?.registerDetail) {
      const options = Object.keys(events.registerDetail).map((key) => {
        const raceDetail = events.registerDetail[key];
        return {
          key: key,
          name: key,
          value: key,
          distance: raceDetail.distance,
          delivery: raceDetail.delivery,
          lifeInsurance: raceDetail.lifeInsurance,
          price: raceDetail.price,
          shirtSize: raceDetail.shirtSize,
          shirtSizeMale: raceDetail.shirtSizeMale,
          shirtSizeFemale: raceDetail.shirtSizeFemale,
          shirtImage: raceDetail.shirtImage,
          typeDetailMale: raceDetail.typeDetailMale,
          typeDetailFemale: raceDetail.typeDetailFemale,
          formDetail: raceDetail.formDetail,
        };
      });
      setRaceOptions(options);
      const defaultRaceOption = options.length > 0 ? options[0].key : "";
      setActiveTab(defaultRaceOption);
      form.setFieldsValue({ raceOption: defaultRaceOption });
    } else {
      console.error("registerDetail is undefined or null");
    }
  }, [events]); // แค่ตรวจสอบว่าเมื่อใดที่ events เปลี่ยนแปลง

  React.useEffect(() => {
    if (raceOptions.length > 0 && !selectedRace) {
      setSelectedRace(raceOptions[0].key);
    }
  }, [raceOptions, selectedRace]);

  React.useEffect(() => {
    const getEventData = async () => {
      setLoading(true); // แสดง loading ขณะรอข้อมูล
      const { eventData } = await fetchEventById(eventId, language);
      setEvents(eventData);
      setLoading(false);
    };

    getEventData();
  }, [eventId, language]);

  // const raceType = events?.[activeTab];

  const deliveryPrice = selectedRaceOption?.delivery;
  const lifeInsurancePrice = selectedRaceOption?.lifeInsurance;
  const racePrice = selectedRaceOption?.price;
  const priceBase = selectedRaceOption?.price ? racePrice : 0;

  const priceDelivery = selectedOptions.delivery ? deliveryPrice : 0;
  const priceLifeInsurance = selectedOptions.lifeInsurance ? lifeInsurancePrice : 0;

  const totalPrice = priceBase + priceDelivery + priceLifeInsurance;

  const handleTab = (tabValue) => {
    setSelectedRace(tabValue);
    setActiveTab(tabValue);
    setSelectedOptions({
      delivery: false,
      lifeInsurance: false,
    });
    form.setFieldsValue({ raceOption: tabValue });
    form.resetFields(["title", "delivery", "lifeInsurance"]);
    handleTitleChange(tabValue);
  };

  const handleTitleChange = (value) => {
    // อัปเดต options สำหรับ age group ตามคำนำหน้าชื่อที่เลือก
    if (value === t("sport-register-form-page.title-32")) {
      setAgeGroupOptions(selectedRaceOption?.typeDetailMale || []);
      setShirtSizeOptions(selectedRaceOption?.shirtSizeMale || []);
    } else if (value === t("sport-register-form-page.title-33") || value === t("sport-register-form-page.title-34")) {
      setAgeGroupOptions(selectedRaceOption?.typeDetailFemale || []);
      setShirtSizeOptions(selectedRaceOption?.shirtSizeFemale || []);
    } else {
      setAgeGroupOptions([]);
      setShirtSizeOptions([]);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const formData = { ...values, totalPrice };
      const currentDate = dayjs();
      const formattedDate = currentDate.format("DD/MM/YYYY");
      const formattedDateStorage = currentDate.format("DD-MM-YYYY");
      const formattedTime = currentDate.format("HH:mm:ss");

      formData.date = formattedDate;
      formData.time = formattedTime;

      if (fileList.length === 0) {
        notification.error({
          message: t("sport-register-form-page.title-35"),
          description: t("sport-register-form-page.title-36"),
        });
        return;
      }

      if (fileList.length > 0) {
        setLoadingCard(true);
        await new Promise(async (resolve, reject) => {
          const fileName = `${eventId}/paymentSlip/${user?.email}_${formattedDateStorage}_${formattedTime}`;
          const reader = new FileReader();
          reader.readAsDataURL(fileList[0].originFileObj);
          reader.onload = async (e) => {
            const rawData = e.target.result;
            const fileURL = await postImageSlip(fileName, rawData);
            if (fileURL) {
              formData.qrImage = fileURL;
              console.log("fileURL:  " + formData?.qrImage);
              resolve(); // อัปโหลดสำเร็จ
            } else {
              console.error("Failed to upload the image slip.");
              reject(new Error("Failed to upload image")); // อัปโหลดไม่สำเร็จ
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file")); // ข้อผิดพลาดในการอ่านไฟล์
          };
        });
      }

      const membersData = [];

      const memberData = {
        date: formattedDate,
        time: formattedTime,
        uid: user?.uid,
        address: formData.address,
        ageGroup: formData.ageGroup,
        birthDate: formData.birthDate,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        qrImage: formData.qrImage,
        raceOption: formData.raceOption,
        note: formData.note || "",
        idPass: formData.idPass || "",
        otherContact: formData.otherContact || "",
        bloodGroup: formData.bloodGroup || "",
        // otherPhone: formData.otherPhone || "",
        delivery: formData.delivery || false,
        lifeInsurance: formData.lifeInsurance || false,
        shirtSize: formData.shirtSize,
        title: formData.title,
        totalPrice: formData.totalPrice,
        status: "pending",
        refAcc: user?.email,
        groupName: formData.groupName || "",
      };
      membersData.push(memberData);

      // เรียกใช้ API สำหรับเพิ่มสมาชิกใหม่
      const response = await pushNewMember(`${activeButton}`, eventId, membersData);

      if (response === "Success") {
        form.resetFields();
        setFileList([]);
        navigate(`/${language}/sport-register/${activeButton}/member/${eventId}`);

        notification.open({
          message: null,
          description: (
            <div style={{ textAlign: "center" }}>
              <img
                src="/picture-success.jpg"
                alt="Success"
                style={{
                  width: "400px",
                  height: "auto",
                  marginBottom: "16px",
                  borderRadius: "8px",
                }}
              />
            </div>
          ),
          style: {
            width: "auto",
            background: "transparent",
            boxShadow: "none",
          },
          duration: 3,
          placement: "top",
        });
        return;
      } else if (response !== "Success") {
        Modal.error({
          title: t("sport-register-form-page.title-77"),
          content: (
            <div style={{ fontSize: "24px" }}>
              {t("sport-register-form-page.title-78")} <strong>{response}</strong>
              <br />
              {t("sport-register-form-page.title-79")}
              {t("sport-register-form-page.title-80")}
            </div>
          ),
          width: 800,
          centered: true,
          onOk() {},
        });
      }
    } catch (error) {
      notification.error({
        message: t("sport-register-form-page.title-38"),
        description: t("sport-register-form-page.title-39"),
      });
    } finally {
      setLoadingCard(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (loadingCard) {
    return <OverlayLoading />;
  }

  const handleClearImage = () => {
    setFileList([]);
  };

  const beforeUpload = (file) => {
    // Example: Limit file size to 2MB
    const isValidSize = file.size / 1024 / 1024 < 3;
    if (!isValidSize) {
      notification.error({
        message: t("sport-register-form-page.title-40"),
        description: t("sport-register-form-page.title-41"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    // Example: Allow only jpg and png files
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("sport-register-form-page.title-42"),
        description: t("sport-register-form-page.title-43"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    return isValidSize && isJpgOrPng; // Return false to prevent upload
  };

  return (
    <>
      <Card
        // loading={loadingCard}
        style={{
          backgroundColor: "rgba(94, 114, 235, 0.2)",
          borderRadius: 0,
          borderTop: "15px solid ",
          borderColor: "#130C63",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
      >
        {screens.xs ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="tab-select-form-self"
          >
            <Select defaultValue="1" onChange={handleTabRegister} style={{ width: "50%" }}>
              <Select.Option value="1">{t("sport-register-form-page.header-sub-card-button-1")}</Select.Option>
              <Select.Option value="2">{t("sport-register-form-page.header-sub-card-button-2")}</Select.Option>
              <Select.Option value="3">{t("sport-register-form-page.header-sub-card-button-3")}</Select.Option>
              {events?.onlyShirt !== undefined ? (
                <Select.Option value="4">{t("sport-register-form-page.header-sub-card-button-4")}</Select.Option>
              ) : null}
            </Select>
          </div>
        ) : (
          <Row gutter={[16, 32]}>
            <Col xs={4} sm={4} md={4} lg={4} xl={3} xxl={3} offset={3}>
              <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("1")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-1")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={6} sm={5} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[1]} onClick={() => handleTabRegister("2")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-2")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={5} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[2]} onClick={() => handleTabRegister("3")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-3")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={5} md={4} lg={4} xl={3} xxl={3}>
              {events?.onlyShirt !== undefined ? (
                <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("4")}>
                  <Space direction="vertical" align="center">
                    <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                      {t("sport-register-form-page.header-sub-card-button-4")}
                    </Text>
                  </Space>
                </Button>
              ) : null}
            </Col>
          </Row>
        )}

        <Form form={form} layout="vertical">
          <Card
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "0 auto",
              borderTop: "15px solid",
              borderColor: "#130C63",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
              borderRadius: "40px",
              padding: "20px",
            }}
          >
            <Row justify="center" align="middle" gutter={[16, 16]}>
              {events?.formDetail?.title ? (
                <Col xs={24} sm={12} md={8} lg={5} xl={4} xxl={4}>
                  <Form.Item
                    label={t("sport-register-form-page.title-1")}
                    name="title"
                    layout="horizontal"
                    rules={[{ required: true, message: t("sport-register-form-page.title-44") }]}
                  >
                    <Select className="title-select" onChange={handleTitleChange}>
                      <Select.Option value={t("sport-register-form-page.title-32")}>
                        {t("sport-register-form-page.title-32")}
                      </Select.Option>
                      <Select.Option value={t("sport-register-form-page.title-33")}>
                        {t("sport-register-form-page.title-33")}
                      </Select.Option>
                      <Select.Option value={t("sport-register-form-page.title-34")}>
                        {t("sport-register-form-page.title-34")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.firstName ? (
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label={t("sport-register-form-page.title-2")}
                    name="firstName"
                    rules={[{ required: true, message: t("sport-register-form-page.title-45") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.lastName ? (
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label={t("sport-register-form-page.title-3")}
                    name="lastName"
                    rules={[{ required: true, message: t("sport-register-form-page.title-46") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.phone ? (
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label={t("sport-register-form-page.title-4")}
                    name="phone"
                    rules={[{ required: true, message: t("sport-register-form-page.title-47") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.birthDate ? (
                <Col xs={12} sm={15} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    label={t("sport-register-form-page.title-10")}
                    name="birthDate"
                    rules={[{ required: true, message: t("sport-register-form-page.title-52") }]}
                    layout="horizontal"
                  >
                    <Input name="birthDate" layout="horizontal" placeholder={t("sport-register-form-page.title-75")} />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.idPass ? (
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={t("sport-register-form-page.title-5")}
                    name="idPass"
                    // rules={[{ required: true, message: t("sport-register-form-page.title-48") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.otherContact ? (
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={t("sport-register-form-page.title-6")}
                    name="otherContact"
                    // rules={[{ required: true, message: t("sport-register-form-page.title-49") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.bloodGroup ? (
                <Col xs={12} sm={10} md={8} lg={6} xl={4} xxl={4}>
                  <Form.Item
                    label={t("sport-register-form-page.title-7")}
                    name="bloodGroup"
                    layout="horizontal"
                    // rules={[{ required: true, message: t("sport-register-form-page.title-50") }]}
                  >
                    <Select className="title-select">
                      <Select.Option value="A">A</Select.Option>
                      <Select.Option value="B">B</Select.Option>
                      <Select.Option value="AB">AB</Select.Option>
                      <Select.Option value="O">O</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {/* {events?.formDetail?.otherPhone ? (
                <Col xs={12} sm={15} md={12} lg={10} xl={6} xxl={6}>
                  <Form.Item
                    label={t("sport-register-form-page.title-8")}
                    name="otherPhone"
                    // rules={[{ required: true, message: t("sport-register-form-page.title-51") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )} */}
              {events?.formDetail?.groupName ? (
                <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Form.Item label={t("sport-register-form-page.title-11")} name="groupName" layout="horizontal">
                    <Input name="groupName" layout="horizontal" />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.address ? (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={t("sport-register-form-page.title-12")}
                    name="address"
                    rules={[{ required: true, message: t("sport-register-form-page.title-53") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {events?.formDetail?.note ? (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={t("sport-register-form-page.title-76")}
                    name="note"
                    // rules={[{ required: true, message: t("sport-register-form-page.title-48") }]}
                    layout="horizontal"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Row>

            <Form.Item name="raceOption" initialValue={selectedRaceOption?.key} style={{ display: "none" }}>
              <Input value={selectedRaceOption?.key} />
            </Form.Item>
          </Card>
          {screens.xs ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px 0px 0px 0px",
              }}
              className="tab-select-race"
            >
              <Select
                defaultValue={`${defaultRaceOption}`}
                value={selectedRace}
                onChange={handleTab}
                style={{ width: "50%" }}
              >
                {raceOptions.length > 0 &&
                  raceOptions.map((option) => (
                    <Select.Option key={option.key} value={option.key}>
                      {option.name.toUpperCase()}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          ) : (
            <Row gutter={[16, 32]} style={{ margin: "10px 0px 0px 0px", rowGap: 0 }}>
              {raceOptions.map((option) => (
                <Col
                  key={option.key}
                  xs={0}
                  sm={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 2 }}
                  lg={4}
                  xl={3}
                  xxl={{ span: 3, offset: 2 }}
                >
                  <Button
                    block
                    size="large"
                    type={activeTab === option.value ? "primary" : "default"}
                    onClick={() => handleTab(option.value)}
                    style={{
                      borderRadius: "10px 10px 0px 0px",
                      backgroundColor: activeTab === option.value ? "#ffffff" : "#d9d9d9",
                      color: "#000000",
                    }}
                  >
                    <Text strong style={{ margin: 0 }}>
                      {option.name.toUpperCase()}
                    </Text>
                  </Button>
                </Col>
              ))}
            </Row>
          )}
          <Card
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "0 auto",
              borderRadius: "40px",
            }}
          >
            <Row>
              <Col offset={1}>
                {`${t("sport-register-form-page.title-13")} ${selectedRaceOption?.distance} Km. ${t(
                  "sport-register-form-page.title-14"
                )}: ${selectedRaceOption?.name} ${t("sport-register-form-page.title-15")} ${
                  selectedRaceOption?.price
                } ${t("sport-register-form-page.title-16")}`}
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={8} md={12} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="ageGroup"
                  rules={[{ required: true, message: t("sport-register-form-page.title-54") }]}
                >
                  <Radio.Group style={{ display: "flex", flexDirection: "column" }}>
                    <Space size="middle" direction="vertical">
                      {ageGroupOptions.length > 0 ? (
                        ageGroupOptions.map((value, index) => (
                          <Radio
                            key={index}
                            value={value}
                            style={{ marginBottom: "10px", marginTop: index === 0 ? "10px" : "0px" }}
                          >
                            {value}
                          </Radio>
                        ))
                      ) : (
                        <Title level={5}>{t("sport-register-form-page.title-17")}</Title>
                      )}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={16} md={12} lg={8} xl={8} xxl={8}>
                <Image src={raceOptions.find((option) => option.key === activeTab)?.shirtImage} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="shirtSize"
                  rules={[{ required: true, message: t("sport-register-form-page.title-55") }]}
                >
                  <Radio.Group>
                    {Object.keys(shirtSizeOptions).length > 0 ? (
                      <>
                        <Title level={4}>{t("sport-register-form-page.header-sizeShirt-1")}</Title>
                        <Row gutter={[16, 16]} wrap>
                          {Object.entries(shirtSizeOptions)
                            .sort(([, aChest], [, bChest]) => {
                              const chestA = parseInt(aChest.split(" ")[1], 10);
                              const chestB = parseInt(bChest.split(" ")[1], 10);
                              return chestA - chestB;
                            })
                            .map(([size, chest]) => (
                              <Col xs={24} sm={16} md={12} lg={12} xl={12} xxl={12} key={size}>
                                <Radio value={size}>
                                  {size} {chest}
                                </Radio>
                              </Col>
                            ))}
                        </Row>
                      </>
                    ) : (
                      <Title level={5}>{t("sport-register-form-page.title-18")}</Title>
                    )}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Row gutter={[16, 32]} style={{ margin: "20px 0px 0px 0px", rowGap: 0 }}>
            <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18} offset={3}>
              <Text style={{ margin: 0, fontSize: "18px" }}>{t("sport-register-form-page.title-19")}</Text>
            </Col>
          </Row>
          <Card
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "0 auto",
              borderRadius: "40px",
              marginTop: "10px",
            }}
          >
            {selectedRaceOption?.delivery != null || selectedRaceOption?.lifeInsurance != null ? (
              <>
                <Form.Item name="delivery" valuePropName="checked">
                  <Checkbox
                    style={{ display: "flex" }}
                    disabled={selectedRaceOption?.delivery == null}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setSelectedOptions((prev) => ({
                        ...prev,
                        delivery: checked,
                      }));
                    }}
                  >
                    {selectedRaceOption?.delivery != null
                      ? `${t("sport-register-form-page.title-21")} ${selectedRaceOption?.delivery} ${t(
                          "sport-register-form-page.title-16"
                        )}`
                      : t("sport-register-form-page.title-56")}
                  </Checkbox>
                </Form.Item>
                <Form.Item name="lifeInsurance" valuePropName="checked">
                  <Checkbox
                    style={{ display: "flex" }}
                    disabled={selectedRaceOption?.lifeInsurance == null}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setSelectedOptions((prev) => ({
                        ...prev,
                        lifeInsurance: checked,
                      }));
                    }}
                  >
                    {selectedRaceOption?.lifeInsurance != null
                      ? `${t("sport-register-form-page.title-22")} ${selectedRaceOption.lifeInsurance} ${t(
                          "sport-register-form-page.title-16"
                        )}`
                      : t("sport-register-form-page.title-57")}
                  </Checkbox>
                </Form.Item>
              </>
            ) : (
              <Text>{t("sport-register-form-page.title-20")}</Text>
            )}
          </Card>
        </Form>
        <Form form={form} layout="vertical">
          <Card
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "0 auto",
              borderRadius: "40px",
              padding: "20px 40px 20px 40px",
              marginTop: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Row justify="center" align="middle" gutter={[32, 32]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src={qrCodeData?.qrPayment1} />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src={qrCodeData?.qrPayment2} />
              </Col> */}
              <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={{ span: 6, offset: 2 }}>
                <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                  {t("sport-register-form-page.title-24")}
                </Title>
                <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                  {t("sport-register-form-page.title-25")}
                </Title>
                <Form.Item name="qrImage" layout="horizontal">
                  {fileList.length > 0 ? (
                    <div style={{ position: "relative" }}>
                      <Image src={URL.createObjectURL(fileList[0].originFileObj)} alt="Image preview" />
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={handleClearImage}
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          border: "none",
                        }}
                      />
                    </div>
                  ) : (
                    <Upload.Dragger
                      listType="picture"
                      name="files"
                      maxCount={1}
                      beforeUpload={beforeUpload}
                      onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                      showUploadList={false}
                      customRequest={() => {
                        /* no action required */
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <CameraOutlined />
                      </p>
                      <p className="ant-upload-text">{t("sport-register-form-page.title-26")}</p>
                    </Upload.Dragger>
                  )}
                </Form.Item>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text>{t("sport-register-form-page.title-27")}</Text>
                  </Col>
                  <Col>
                    <Text>
                      {priceBase} {t("sport-register-form-page.title-16")}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text>{t("sport-register-form-page.title-28")}</Text>
                  </Col>
                  <Col>
                    <Text>
                      {priceDelivery} {t("sport-register-form-page.title-16")}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Text>{t("sport-register-form-page.title-29")}</Text>
                  </Col>
                  <Col>
                    <Text>
                      {priceLifeInsurance} {t("sport-register-form-page.title-16")}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Title level={5} style={{ margin: 0, color: "#038416" }}>
                      {t("sport-register-form-page.title-30")}
                    </Title>
                  </Col>
                  <Col>
                    <Title level={5} style={{ margin: 0, color: "#038416" }}>
                      {totalPrice} {t("sport-register-form-page.title-16")}
                    </Title>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Form>
      </Card>
      <Flex justify="center" style={{ padding: "20px 0px 10px 0px" }}>
        <Button
          type="text"
          size="large"
          onClick={handleSubmit}
          loading={loading}
          style={{ backgroundColor: "#038416", color: "#FFFFFF" }}
        >
          {t("sport-register-form-page.button-send")}
        </Button>
      </Flex>
    </>
  );
};

export default FormRegisterSelf;
